<template>
<div class="container">
   <div v-if="courses.length > 0" class="row">
          <div v-for="(course,index) in courses" :key="index" class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 grid-item">
              <div class="course__item white-bg mb-30 fix">
                 <div class="course__thumb w-img p-relative fix">
                     <router-link v-if="!course.proximamente && course.tipo_registro=='CURSO'" :to="`/curso/${course.codigo}`" :key="course.token">
                       <img v-if="course.course_image" :src="course.course_image" alt="course-image" style="">
                       <img v-else src="@/assets/img/pages/not-available.png" alt="course-image" style="">
                    </router-link>
                     <router-link v-else-if="!course.proximamente && course.tipo_registro=='PROGRAMA'" :to="`/programa/${course.codigo}`" :key="course.token">
                       <img v-if="course.course_image" :src="course.course_image" alt="course-image" style="">
                       <img v-else src="@/assets/img/pages/not-available.png" alt="course-image" style="">
                    </router-link>
                     <div v-else>
                         <img v-if="course.course_image" :src="course.course_image" alt="course-image" style="">
                         <img v-else src="@/assets/img/pages/not-available.png" alt="course-image" style="">
                     </div>
                    <div class="course__tag">
                      <a v-if="course.proximamente" href="javascript:void(0);" class="orange">Próximamente</a>
                      <a v-else-if="course.tipo_registro=='CURSO'" href="javascript:void(0);" class="green">Curso</a>
                      <a v-else-if="course.tipo_registro=='PROGRAMA'" href="javascript:void(0);" class="green">Programa</a>
                    </div>
                 </div>
                 <div class="course__content">
                    <div class="course__meta d-flex align-items-center justify-content-between" v-if="!course.proximamente">
                       <div class="course__lesson" v-if="course.tipo_registro=='CURSO'">
                          <span><i class="far fa-book-alt"></i>{{ course.modulos.length }} Secciones</span>
                       </div>
                       <div v-if="!agregadoACarrito(course) && !agregadoACarritoEnVista && course.compra_activa && course.tipo_registro=='CURSO'" class="course__rating">
                            <a @click="agregarCursoEnCarrito(course)" href="javascript:void(0);" class="cart-toggle-btn">
                              <div class="header__cart-icon">
                                <svg viewBox="0 0 24 24">
                                  <circle class="st0" cx="9" cy="21" r="1" />
                                  <circle class="st0" cx="20" cy="21" r="1" />
                                  <path
                                    class="st0"
                                    d="M1,1h4l2.7,13.4c0.2,1,1,1.6,2,1.6h9.7c1,0,1.8-0.7,2-1.6L23,6H6"
                                  />
                                </svg>
                              </div>
                            </a>
                            <v-tooltip
                              activator="parent"
                              location="bottom"
                            >
                                Agregar al carrito
                            </v-tooltip>
                       </div>
                    </div>
                    <h3 class="course__title">
                       <router-link v-if="!course.proximamente && course.tipo_registro=='CURSO'" :to="`/curso/${course.codigo}`" :key="course.token">
                          {{ course.name }}
                       </router-link>
                       <router-link v-else-if="!course.proximamente && course.tipo_registro=='PROGRAMA'" :to="`/programa/${course.codigo}`" :key="course.token">
                          {{ course.name }}
                       </router-link>
                        <span v-else>{{ course.name }}</span>
                    </h3>
                    <div class="course__teacher d-flex align-items-center" v-for="teacher in course.teachers" :key="teacher.token">
                       <div class="course__teacher-thumb mr-15">
                          <a v-if="teacher.enlace_linkedin" :href="teacher.enlace_linkedin" target="_blank">
                            <img :src="teacher.avatar_image_url" alt="">
                          </a>

                          <img :src="teacher.avatar_image_url" alt="" v-else>
                       </div>
                       <h6>
                          <b>Docente: </b>
                          <a v-if="teacher.enlace_linkedin" :href="teacher.enlace_linkedin" target="_blank"> {{ teacher.display_name }}</a>
                          <a v-else href="#"> {{ teacher.display_name }}</a>
                       </h6>
                    </div>
                 </div>
                 <div class="course__more d-flex justify-content-between align-items-center" v-if="!course.proximamente">
                    <div v-if="!course.tiene_modalidad_hibrida && !course.tiene_modalidad_virtual">
                        <div class="course__status d-flex align-items-center" v-if="course.info_descuento_promocion && course.info_descuento_promocion.aplica_descuento">
                           <span class="green">$ {{ course.info_descuento_promocion.precio_postdescuento }}</span>
                           <span class="old-price">$ {{ course.precio}}</span>
                        </div>
                        <div class="course__status d-flex align-items-center" v-else-if="course.info_descuento_promocion && !course.info_descuento_promocion.aplica_descuento">
                           <span class="green">$ {{ course.precio }}</span>
                        </div>
                     </div>

                     <div v-else class="row">
                         <div v-if="course.tiene_modalidad_hibrida" class="course__status d-flex flex-column align-items-center col-5">
                             <span v-if="course.info_descuento_promocion && course.info_descuento_promocion.aplica_descuento" class="green">$ {{ course.info_descuento_promocion.precio_postdescuento_hibrida }}</span>
                             <span v-else-if="course.info_descuento_promocion && !course.info_descuento_promocion.aplica_descuento" class="green">$ {{ course.precio_modalidad_hibrida }}</span>
                             <div class="course__tag" style="position: static;">
                                 <a href="#" class="orange">SEMIPRESENCIAL</a>
                             </div>
                         </div>

                         <div class="col-1">

                         </div>

                          <div v-if="course.tiene_modalidad_virtual" class="course__status d-flex flex-column col-5">
                             <span v-if="course.info_descuento_promocion && course.info_descuento_promocion.aplica_descuento" class="green">$ {{ course.info_descuento_promocion.precio_postdescuento_virtual }}</span>
                             <span v-else-if="course.info_descuento_promocion && !course.info_descuento_promocion.aplica_descuento" class="green">$ {{ course.precio_modalidad_virtual }}</span>
                             <div class="course__tag" style="position: static;">
                                 <a href="#" class="orange">ONLINE</a>
                             </div>
                          </div>
                     </div>

                    <div class="course__btn">
                        <router-link :to="`/curso/${course.codigo}`" :key="course.token" class="link-btn" v-if="course.tipo_registro=='CURSO'">
                          Ver detalles
                          <i class="far fa-arrow-right"></i>
                          <i class="far fa-arrow-right"></i>
                        </router-link>
                        <router-link :to="`/programa/${course.codigo}`" :key="course.token" class="link-btn" v-if="course.tipo_registro=='PROGRAMA'">
                          Ver detalles
                          <i class="far fa-arrow-right"></i>
                          <i class="far fa-arrow-right"></i>
                        </router-link>
                    </div>
                 </div>
              </div>
          </div>

          <div v-if="$route.name=='consulta-programas'" class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 grid-item">
              <div class="course__item white-bg mb-30 fix">
                 <div class="course__thumb w-img p-relative fix">
                     <router-link :to="`/cursos`">
                       <img src="@/assets/img/logiga/otros_cursos.jpeg" alt="course-image" style="height: 256px;">
                    </router-link>
                    <div class="course__tag">
                      <a href="javascript:void(0);" class="green">Cursos</a>
                    </div>
                 </div>
                 <div class="course__content">
                    <div class="course__meta d-flex align-items-center justify-content-between">
                       <div class="course__lesson">
                          <span><i class="far fa-book-alt"></i>Cursos disponibles</span>
                       </div>
                    </div>
                    <h3 class="course__title">
                       <router-link :to="`/cursos`">
                          Ver todos los cursos
                       </router-link>
                    </h3>
                 </div>
                 <div class="course__more d-flex justify-content-between align-items-center">
                    <div class="course__btn">
                        <router-link :to="`/cursos`" class="link-btn">
                          Ver detalles
                          <i class="far fa-arrow-right"></i>
                          <i class="far fa-arrow-right"></i>
                        </router-link>
                    </div>
                 </div>
              </div>
          </div>
   </div>

  <div v-else>
      <div class="mt-5 text-center">
          <p>No hay registros para mostrar</p>
      </div>
  </div>
</div>
</template>

<script>
import CourseItemsMixin from '../../mixins/courseItemsMixin';
// import rutasjson from "@/assets/utils/rutas.json";
import {useCookies} from "vue3-cookies";
import axios from "@/axios";

export default {
     name:'CoursesAllItems',
     mixins:[CourseItemsMixin],
     props:{
         courses:Object,
     },
    setup() {
      const { cookies } = useCookies();
      return { cookies };
    },
    data() {
      return {
        agregadoACarritoEnVista: false,
      }
    },
    methods:{
       agregarCursoEnCarrito(course){
           let carrito =  JSON.parse(localStorage.getItem("carritoLogiga"))
            // let carrito =  JSON.parse(this.cookies.get("carritoLogiga"))

           let course_dict = {
              token: course.token,
              name: course.name,
              canvas_id: course.canvas_id,
              course_image: course.course_image,
              precio: course.precio,
              teachers: course.teachers,
              codigo: course.codigo,
              info_descuento_promocion: course.info_descuento_promocion,
              tipo_registro: course.tipo_registro,
               modalidad_seleccionada: 'SEMIPRESENCIAL',
             tiene_modalidad_hibrida: this.tiene_modalidad_hibrida,
             tiene_modalidad_virtual: this.tiene_modalidad_virtual
           }

         carrito.push(course_dict)
         this.$store.commit('agregarCursoEnCarrito', course_dict)

         let subtotal = 0.00
          carrito.forEach((course) => {
             let precio = 0.00
             if(course.info_descuento_promocion && course.info_descuento_promocion['aplica_descuento']){
                precio += parseFloat(course.info_descuento_promocion['precio_postdescuento'])
             }
             else{
                precio += parseFloat(course.precio)
             }
              subtotal += precio
          })

          this.$store.commit('setSubtotal', subtotal.toFixed(2))
           localStorage.setItem("carritoSubtotal", subtotal.toFixed(2))
          // this.cookies.set("carritoSubtotal", subtotal.toFixed(2), '30d', '', rutasjson.domain)

         //  console.log("carrito despues de agregar en carrito es-------->")
         // console.log(carrito)
         this.$store.commit('setCarrito', carrito)
           localStorage.setItem("carritoLogiga", JSON.stringify(carrito))
         // this.cookies.set("carritoLogiga", JSON.stringify(carrito), '30d', '', rutasjson.domain)

         // console.log("Carrito guardado en cookie es------->")
         //  console.log(JSON.parse(this.cookies.get("carritoLogiga")))


          this.$swal({
              title: 'Agregado',
              text: 'Fue agregado al carrito exitosamente',
              icon: 'success',
              confirmButtonColor: '#0F80C1',
              confirmButtonText: 'Aceptar',
          })

          this.registrarAgregarACarrito(course)
          this.agregadoACarritoEnVista = true
       },
         registrarAgregarACarrito(course){
             axios.post('api/orden/agregar-curso-carrito/',{
                 curso: course.token,
                 tipo_registro: course.tipo_registro
             })
         },
        agregadoACarrito(course) {
            let carrito = JSON.parse(localStorage.getItem("carritoLogiga"))
            // let carrito =  JSON.parse(this.cookies.get("carritoLogiga"))
            let agregado = false
          // console.log("agregado a carrito-----------------")
            carrito.forEach(function(curso) {
                if(curso['codigo'] === course.codigo){
                    agregado = true
                }
            })
            return agregado
        }
    },
    computed: {

    },
};
</script>
