<template>
    <loading
        v-model:active="mostrarLoader"
        color="#0F80C1"
        loader="dots"
    />
<!--   <CouponArea/>-->
    <section class="checkout-area pb-70 pt-100">
      <div class="container" v-if="carrito.length > 0">
         <Form @submit="checkSubmitCheckoutForm" :validation-schema="getValidationSchema">
            <div class="row">
                  <div class="col-lg-6">
                     <div class="checkbox-form">
                        <h3>Datos de Facturación</h3>
                        <div class="row mb-40">
                              <div class="col-md-6">
                                 <div class="checkout-form-list">
                                    <Field name="nombres" v-slot="{ field, errors, errorMessage }">
                                        <label>Nombres <span class="required">*</span></label>
                                        <input type="text" placeholder="Ingrese nombres..." v-model="datosFacturacion.nombres" v-bind="field" class="form-control" :class="[{'is-invalid': errors.length>0}]" maxlength="100"/>
                                        <span class="text-danger">{{ errorMessage }}</span>
                                    </Field>
                                 </div>
                              </div>
                              <div class="col-md-6">
                                 <div class="checkout-form-list">
                                    <Field name="apellidos" v-slot="{ field, errors, errorMessage }">
                                        <label>Apellidos <span class="required">*</span></label>
                                        <input type="text" placeholder="Ingrese apellidos..." v-model="datosFacturacion.apellidos" v-bind="field" class="form-control" :class="[{'is-invalid': errors.length>0}]" maxlength="100"/>
                                        <span class="text-danger">{{ errorMessage }}</span>
                                    </Field>
                                 </div>
                              </div>
                              <div class="col-md-6">
                                 <div class="checkout-form-list">
                                    <Field name="documento_identidad" v-slot="{ field, errors, errorMessage }">
                                        <label>Cédula/RUC<span class="required">*</span></label>
                                        <input type="text" placeholder="Ingrese cédula o RUC..." v-model="datosFacturacion.documento_identidad" v-bind="field" class="form-control" :class="[{'is-invalid': errors.length>0}]" maxlength="13"/>
                                        <span class="text-danger">{{ errorMessage }}</span>
                                    </Field>
                                 </div>
                              </div>
                              <div class="col-md-6">
                                 <div class="checkout-form-list">
                                    <Field name="email" v-slot="{ field, errors, errorMessage }">
                                        <label>Correo Electrónico <span class="required">*</span></label>
                                        <input type="email" placeholder="Ingrese correo..." v-model="datosFacturacion.email" v-bind="field" class="form-control" :class="[{'is-invalid': errors.length>0}]" maxlength="50"/>
                                        <span class="text-danger">{{ errorMessage }}</span>
                                    </Field>
                                 </div>
                              </div>
                              <div class="col-md-12">
                                 <div class="checkout-form-list">
                                    <label>Compañía</label>
                                    <input type="text" placeholder="Ingrese compañía..." v-model="datosFacturacion.compania" maxlength="100"/>
                                 </div>
                              </div>
                              <div class="col-md-12">
                                 <div class="country-select">
                                    <label>País <span class="required">*</span></label>
                                    <select v-model="datosFacturacion.pais">
                                     <option value="Afganistan">Afghanistan</option>
                                     <option value="Albania">Albania</option>
                                     <option value="Algeria">Algeria</option>
                                     <option value="American Samoa">American Samoa</option>
                                     <option value="Andorra">Andorra</option>
                                     <option value="Angola">Angola</option>
                                     <option value="Anguilla">Anguilla</option>
                                     <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                                     <option value="Argentina">Argentina</option>
                                     <option value="Armenia">Armenia</option>
                                     <option value="Aruba">Aruba</option>
                                     <option value="Australia">Australia</option>
                                     <option value="Austria">Austria</option>
                                     <option value="Azerbaijan">Azerbaijan</option>
                                     <option value="Bahamas">Bahamas</option>
                                     <option value="Bahrain">Bahrain</option>
                                     <option value="Bangladesh">Bangladesh</option>
                                     <option value="Barbados">Barbados</option>
                                     <option value="Belarus">Belarus</option>
                                     <option value="Belgium">Belgium</option>
                                     <option value="Belize">Belize</option>
                                     <option value="Benin">Benin</option>
                                     <option value="Bermuda">Bermuda</option>
                                     <option value="Bhutan">Bhutan</option>
                                     <option value="Bolivia">Bolivia</option>
                                     <option value="Bonaire">Bonaire</option>
                                     <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                                     <option value="Botswana">Botswana</option>
                                     <option value="Brazil">Brazil</option>
                                     <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                                     <option value="Brunei">Brunei</option>
                                     <option value="Bulgaria">Bulgaria</option>
                                     <option value="Burkina Faso">Burkina Faso</option>
                                     <option value="Burundi">Burundi</option>
                                     <option value="Cambodia">Cambodia</option>
                                     <option value="Cameroon">Cameroon</option>
                                     <option value="Canada">Canada</option>
                                     <option value="Canary Islands">Canary Islands</option>
                                     <option value="Cape Verde">Cape Verde</option>
                                     <option value="Cayman Islands">Cayman Islands</option>
                                     <option value="Central African Republic">Central African Republic</option>
                                     <option value="Chad">Chad</option>
                                     <option value="Channel Islands">Channel Islands</option>
                                     <option value="Chile">Chile</option>
                                     <option value="China">China</option>
                                     <option value="Christmas Island">Christmas Island</option>
                                     <option value="Cocos Island">Cocos Island</option>
                                     <option value="Colombia">Colombia</option>
                                     <option value="Comoros">Comoros</option>
                                     <option value="Congo">Congo</option>
                                     <option value="Cook Islands">Cook Islands</option>
                                     <option value="Costa Rica">Costa Rica</option>
                                     <option value="Cote DIvoire">Cote DIvoire</option>
                                     <option value="Croatia">Croatia</option>
                                     <option value="Cuba">Cuba</option>
                                     <option value="Curaco">Curacao</option>
                                     <option value="Cyprus">Cyprus</option>
                                     <option value="Czech Republic">Czech Republic</option>
                                     <option value="Denmark">Denmark</option>
                                     <option value="Djibouti">Djibouti</option>
                                     <option value="Dominica">Dominica</option>
                                     <option value="Dominican Republic">Dominican Republic</option>
                                     <option value="East Timor">East Timor</option>
                                     <option value="Ecuador" selected>Ecuador</option>
                                     <option value="Egypt">Egypt</option>
                                     <option value="El Salvador">El Salvador</option>
                                     <option value="Equatorial Guinea">Equatorial Guinea</option>
                                     <option value="Eritrea">Eritrea</option>
                                     <option value="Estonia">Estonia</option>
                                     <option value="Ethiopia">Ethiopia</option>
                                     <option value="Falkland Islands">Falkland Islands</option>
                                     <option value="Faroe Islands">Faroe Islands</option>
                                     <option value="Fiji">Fiji</option>
                                     <option value="Finland">Finland</option>
                                     <option value="France">France</option>
                                     <option value="French Guiana">French Guiana</option>
                                     <option value="French Polynesia">French Polynesia</option>
                                     <option value="French Southern Ter">French Southern Ter</option>
                                     <option value="Gabon">Gabon</option>
                                     <option value="Gambia">Gambia</option>
                                     <option value="Georgia">Georgia</option>
                                     <option value="Germany">Germany</option>
                                     <option value="Ghana">Ghana</option>
                                     <option value="Gibraltar">Gibraltar</option>
                                     <option value="Great Britain">Great Britain</option>
                                     <option value="Greece">Greece</option>
                                     <option value="Greenland">Greenland</option>
                                     <option value="Grenada">Grenada</option>
                                     <option value="Guadeloupe">Guadeloupe</option>
                                     <option value="Guam">Guam</option>
                                     <option value="Guatemala">Guatemala</option>
                                     <option value="Guinea">Guinea</option>
                                     <option value="Guyana">Guyana</option>
                                     <option value="Haiti">Haiti</option>
                                     <option value="Hawaii">Hawaii</option>
                                     <option value="Honduras">Honduras</option>
                                     <option value="Hong Kong">Hong Kong</option>
                                     <option value="Hungary">Hungary</option>
                                     <option value="Iceland">Iceland</option>
                                     <option value="Indonesia">Indonesia</option>
                                     <option value="India">India</option>
                                     <option value="Iran">Iran</option>
                                     <option value="Iraq">Iraq</option>
                                     <option value="Ireland">Ireland</option>
                                     <option value="Isle of Man">Isle of Man</option>
                                     <option value="Israel">Israel</option>
                                     <option value="Italy">Italy</option>
                                     <option value="Jamaica">Jamaica</option>
                                     <option value="Japan">Japan</option>
                                     <option value="Jordan">Jordan</option>
                                     <option value="Kazakhstan">Kazakhstan</option>
                                     <option value="Kenya">Kenya</option>
                                     <option value="Kiribati">Kiribati</option>
                                     <option value="Korea North">Korea North</option>
                                     <option value="Korea Sout">Korea South</option>
                                     <option value="Kuwait">Kuwait</option>
                                     <option value="Kyrgyzstan">Kyrgyzstan</option>
                                     <option value="Laos">Laos</option>
                                     <option value="Latvia">Latvia</option>
                                     <option value="Lebanon">Lebanon</option>
                                     <option value="Lesotho">Lesotho</option>
                                     <option value="Liberia">Liberia</option>
                                     <option value="Libya">Libya</option>
                                     <option value="Liechtenstein">Liechtenstein</option>
                                     <option value="Lithuania">Lithuania</option>
                                     <option value="Luxembourg">Luxembourg</option>
                                     <option value="Macau">Macau</option>
                                     <option value="Macedonia">Macedonia</option>
                                     <option value="Madagascar">Madagascar</option>
                                     <option value="Malaysia">Malaysia</option>
                                     <option value="Malawi">Malawi</option>
                                     <option value="Maldives">Maldives</option>
                                     <option value="Mali">Mali</option>
                                     <option value="Malta">Malta</option>
                                     <option value="Marshall Islands">Marshall Islands</option>
                                     <option value="Martinique">Martinique</option>
                                     <option value="Mauritania">Mauritania</option>
                                     <option value="Mauritius">Mauritius</option>
                                     <option value="Mayotte">Mayotte</option>
                                     <option value="Mexico">Mexico</option>
                                     <option value="Midway Islands">Midway Islands</option>
                                     <option value="Moldova">Moldova</option>
                                     <option value="Monaco">Monaco</option>
                                     <option value="Mongolia">Mongolia</option>
                                     <option value="Montserrat">Montserrat</option>
                                     <option value="Morocco">Morocco</option>
                                     <option value="Mozambique">Mozambique</option>
                                     <option value="Myanmar">Myanmar</option>
                                     <option value="Nambia">Nambia</option>
                                     <option value="Nauru">Nauru</option>
                                     <option value="Nepal">Nepal</option>
                                     <option value="Netherland Antilles">Netherland Antilles</option>
                                     <option value="Netherlands">Netherlands (Holland, Europe)</option>
                                     <option value="Nevis">Nevis</option>
                                     <option value="New Caledonia">New Caledonia</option>
                                     <option value="New Zealand">New Zealand</option>
                                     <option value="Nicaragua">Nicaragua</option>
                                     <option value="Niger">Niger</option>
                                     <option value="Nigeria">Nigeria</option>
                                     <option value="Niue">Niue</option>
                                     <option value="Norfolk Island">Norfolk Island</option>
                                     <option value="Norway">Norway</option>
                                     <option value="Oman">Oman</option>
                                     <option value="Pakistan">Pakistan</option>
                                     <option value="Palau Island">Palau Island</option>
                                     <option value="Palestine">Palestine</option>
                                     <option value="Panama">Panama</option>
                                     <option value="Papua New Guinea">Papua New Guinea</option>
                                     <option value="Paraguay">Paraguay</option>
                                     <option value="Peru">Peru</option>
                                     <option value="Phillipines">Philippines</option>
                                     <option value="Pitcairn Island">Pitcairn Island</option>
                                     <option value="Poland">Poland</option>
                                     <option value="Portugal">Portugal</option>
                                     <option value="Puerto Rico">Puerto Rico</option>
                                     <option value="Qatar">Qatar</option>
                                     <option value="Republic of Montenegro">Republic of Montenegro</option>
                                     <option value="Republic of Serbia">Republic of Serbia</option>
                                     <option value="Reunion">Reunion</option>
                                     <option value="Romania">Romania</option>
                                     <option value="Russia">Russia</option>
                                     <option value="Rwanda">Rwanda</option>
                                     <option value="St Barthelemy">St Barthelemy</option>
                                     <option value="St Eustatius">St Eustatius</option>
                                     <option value="St Helena">St Helena</option>
                                     <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                     <option value="St Lucia">St Lucia</option>
                                     <option value="St Maarten">St Maarten</option>
                                     <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                                     <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                                     <option value="Saipan">Saipan</option>
                                     <option value="Samoa">Samoa</option>
                                     <option value="Samoa American">Samoa American</option>
                                     <option value="San Marino">San Marino</option>
                                     <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                                     <option value="Saudi Arabia">Saudi Arabia</option>
                                     <option value="Senegal">Senegal</option>
                                     <option value="Seychelles">Seychelles</option>
                                     <option value="Sierra Leone">Sierra Leone</option>
                                     <option value="Singapore">Singapore</option>
                                     <option value="Slovakia">Slovakia</option>
                                     <option value="Slovenia">Slovenia</option>
                                     <option value="Solomon Islands">Solomon Islands</option>
                                     <option value="Somalia">Somalia</option>
                                     <option value="South Africa">South Africa</option>
                                     <option value="Spain">Spain</option>
                                     <option value="Sri Lanka">Sri Lanka</option>
                                     <option value="Sudan">Sudan</option>
                                     <option value="Suriname">Suriname</option>
                                     <option value="Swaziland">Swaziland</option>
                                     <option value="Sweden">Sweden</option>
                                     <option value="Switzerland">Switzerland</option>
                                     <option value="Syria">Syria</option>
                                     <option value="Tahiti">Tahiti</option>
                                     <option value="Taiwan">Taiwan</option>
                                     <option value="Tajikistan">Tajikistan</option>
                                     <option value="Tanzania">Tanzania</option>
                                     <option value="Thailand">Thailand</option>
                                     <option value="Togo">Togo</option>
                                     <option value="Tokelau">Tokelau</option>
                                     <option value="Tonga">Tonga</option>
                                     <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                                     <option value="Tunisia">Tunisia</option>
                                     <option value="Turkey">Turkey</option>
                                     <option value="Turkmenistan">Turkmenistan</option>
                                     <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                                     <option value="Tuvalu">Tuvalu</option>
                                     <option value="Uganda">Uganda</option>
                                     <option value="United Kingdom">United Kingdom</option>
                                     <option value="Ukraine">Ukraine</option>
                                     <option value="United Arab Erimates">United Arab Emirates</option>
                                     <option value="United States of America">United States of America</option>
                                     <option value="Uraguay">Uruguay</option>
                                     <option value="Uzbekistan">Uzbekistan</option>
                                     <option value="Vanuatu">Vanuatu</option>
                                     <option value="Vatican City State">Vatican City State</option>
                                     <option value="Venezuela">Venezuela</option>
                                     <option value="Vietnam">Vietnam</option>
                                     <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                                     <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                                     <option value="Wake Island">Wake Island</option>
                                     <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                                     <option value="Yemen">Yemen</option>
                                     <option value="Zaire">Zaire</option>
                                     <option value="Zambia">Zambia</option>
                                     <option value="Zimbabwe">Zimbabwe</option>
                                  </select>
                                 </div>
                              </div>
                              <div class="col-md-12">
                                 <div class="checkout-form-list">
                                    <Field name="direccion" v-slot="{ field, errors, errorMessage }">
                                        <label>Dirección de Domicilio <span class="required">*</span></label>
                                        <input type="text" placeholder="Ingrese dirección..." v-model="datosFacturacion.direccion" v-bind="field" class="form-control" :class="[{'is-invalid': errors.length>0}]" maxlength="300"/>
                                        <span class="text-danger">{{ errorMessage }}</span>
                                    </Field>
                                 </div>
                              </div>
                              <div class="col-md-6">
                                 <div class="checkout-form-list">
                                    <Field name="ciudad" v-slot="{ field, errors, errorMessage }">
                                        <label>Ciudad <span class="required">*</span></label>
                                        <input type="text" placeholder="Ingrese ciudad..." v-model="datosFacturacion.ciudad" v-bind="field" class="form-control" :class="[{'is-invalid': errors.length>0}]" maxlength="100"/>
                                        <span class="text-danger">{{ errorMessage }}</span>
                                    </Field>
                                 </div>
                              </div>

                              <div class="col-md-6">
                                 <div class="checkout-form-list">
                                    <Field name="telefono" v-slot="{ field, errors, errorMessage }">
                                        <label>Teléfono </label>
                                        <input type="text" placeholder="Ingrese teléfono..." v-model="datosFacturacion.telefono" v-bind="field" class="form-control" :class="[{'is-invalid': errors.length>0}]" maxlength="20"/>
                                        <span class="text-danger">{{ errorMessage }}</span>
                                    </Field>
                                 </div>
                              </div>
                              <div class="col-md-12">
                                  <Field name="terminos_condiciones" v-slot="{ field, errorMessage }" type="checkbox" :value="datosFacturacion.terminos_condiciones">
                                       <div class="contact__form-agree d-flex align-items-center mb-20">
                                            <input class="e-check-input" type="checkbox" id="e-agree" v-model="datosFacturacion.terminos_condiciones" v-bind="field" >
                                            <label class="e-check-label" for="e-agree">He leído y acepto los<a href="https://cursos.logigacorp.com/terminos-y-condiciones" target="_blank">Términos y Condiciones</a></label>
                                       </div>
                                      <span class="text-danger">{{ errorMessage }}</span>
                                  </Field>
                              </div>
<!--                              <div class="col-md-12">-->
<!--                                 <div class="checkout-form-list create-acc">-->
<!--                                    <input @click="showCreateAcc" id="cbox" type="checkbox" />-->
<!--                                    <label>Create an account?</label>-->
<!--                                 </div>-->
<!--                                 <div id="cbox_info" :class="`checkout-form-list-->
<!--                                 ${showAcc ? 'show-create-acc' : 'create-account'}`">-->
<!--                                    <p>Create an account by entering the information below. If you are a returning customer please login at the top of the page.</p>-->
<!--                                    <label>Account password <span class="required">*</span></label>-->
<!--                                    <input type="password" placeholder="password" />-->
<!--                                 </div>-->
<!--                              </div>-->
                        </div>
                     </div>
                     <div class="checkbox-form">
                        <h3>Cuenta</h3>
                        <div class="col-md-12">
<!--                           <div class="contact__form-agree d-flex align-items-center mb-40">-->
<!--                              <input class="e-check-input" type="checkbox" id="cuenta-agree" v-model="cuentaCanvas.usuarioTieneCuenta">-->
<!--                              <label class="e-check-label" for="cuenta-agree">Tengo una cuenta</label>-->
<!--                           </div>-->
                            <div class="coupon-info">
                                <p class="coupon-text">
                                    A continuación, indique el correo que desea utilizar para acceder a los cursos.
                                </p>
                                <div class="col-12 checkout-form-list">
                                    <Field name="usuario" v-slot="{ field, errors, errorMessage }">
                                        <label><b>Correo Electrónico</b> <span class="required">*</span></label>
                                        <input type="text" placeholder="Ingrese un correo para vincularlo a los cursos..." v-model="cuentaCanvas.usuario" v-bind="field" class="form-control" :class="[{'is-invalid': errors.length>0}]" maxlength="100"/>
                                        <span class="text-danger">{{ errorMessage }}</span>
                                    </Field>
                                </div>
<!--                                <div class="col-12 checkout-form-list">-->
<!--                                    <Field name="password" v-slot="{ field, errors, errorMessage }">-->
<!--                                        <label><b>Contraseña</b> <span class="required">*</span></label>-->
<!--                                        <input type="password" placeholder="Ingrese su contraseña..." v-model="cuentaCanvas.password" v-bind="field" class="form-control" :class="[{'is-invalid': errors.length>0}]"/>-->
<!--                                        <span class="text-danger">{{ errorMessage }}</span>-->
<!--                                    </Field>-->
<!--                                </div>-->
                            </div>
                        </div>
                     </div>

                     <div class="checkbox-form">
                        <h3>Asesor/Vendedor</h3>
                        <div class="col-md-12">
                            <div class="coupon-info">
                                <p class="coupon-text">
                                    A continuación, indique el nombre de su asesor o vendedor que lo asistió en su compra, caso contrario omita esta sección.
                                </p>
                                <div class="col-12 checkout-form-list">
                                    <Field name="asesor" v-slot="{ field, errors, errorMessage }">
                                        <label><b>Nombres</b></label>
                                        <input type="text" placeholder="Ingrese nombres..." v-model="datosFacturacion.asesor" v-bind="field" class="form-control" :class="[{'is-invalid': errors.length>0}]" maxlength="500"/>
                                        <span class="text-danger">{{ errorMessage }}</span>
                                    </Field>
                                </div>
                            </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="your-order mb-10">
                        <h3>Orden de Compra</h3>
                        <div class="your-order-table table-responsive">
                              <table>
                                 <thead>
                                    <tr>
                                       <th class="product-name"><b>Detalles</b></th>
                                       <th class="product-total" style="text-align: right;"><b>Total</b></th>
                                    </tr>
                                 </thead>
                                 <tbody v-if="aplicaCuponDescuento && cuponDescuento!==''">
                                    <tr class="cart_item" v-for="course in carritoConCupon" :key="course.token">
                                       <td class="product-name" style="padding-left: 30px;">
                                          {{ course.name }} {{ course.modalidad_seleccionada }}<strong class="product-quantity"> × 1</strong>
                                           <div class="course__video-discount" v-if="course.info_descuento_promocion && course.info_descuento_promocion.aplica_descuento">
                                                <span>{{ parseFloat(course.info_descuento_promocion.porcentaje_descuento).toFixed(0) }}% Descuento total</span>
                                            </div>
                                       </td>
                                       <td class="course__status product-total" style="text-align: right;" v-if="course.info_descuento_promocion && course.info_descuento_promocion.aplica_descuento">
                                           <span v-if="metodoPago==='BAN' && course.modalidad_seleccionada==='SEMIPRESENCIAL' && course.tiene_modalidad_hibrida" class="green" style="font-size: 14px;">$ {{ course.info_descuento_promocion.precio_postdescuento_hibrida }}</span>
                                           <span v-else-if="metodoPago==='BAN' && course.modalidad_seleccionada==='ONLINE' && course.tiene_modalidad_virtual" class="green" style="font-size: 14px;">$ {{ course.info_descuento_promocion.precio_postdescuento_virtual }}</span>
                                           <span v-else-if="metodoPago==='BAN'" class="green" style="font-size: 14px;">$ {{ course.info_descuento_promocion.precio_postdescuento }}</span>
                                           <span v-else-if="metodoPago==='TAR' && course.modalidad_seleccionada==='SEMIPRESENCIAL' && course.tiene_modalidad_hibrida" class="green" style="font-size: 14px;">$ {{ course.info_descuento_promocion.precio_final_con_tc_hibrida }}</span>
                                           <span v-else-if="metodoPago==='TAR' && course.modalidad_seleccionada==='ONLINE' && course.tiene_modalidad_virtual" class="green" style="font-size: 14px;">$ {{ course.info_descuento_promocion.precio_final_con_tc_virtual }}</span>
                                           <span v-else-if="metodoPago==='TAR'" class="green" style="font-size: 14px;">$ {{ course.info_descuento_promocion.precio_final_con_tc }}</span>
                                           <span v-else class="green" style="font-size: 14px;">$ {{subtotalCarrito}}</span>
                                           <br>
                                           <span v-if="metodoPago==='BAN'" class="old-price">$ {{ course.precio}}</span>
                                           <span v-else-if="metodoPago==='TAR' && course.modalidad_seleccionada==='SEMIPRESENCIAL' && course.tiene_modalidad_hibrida" class="old-price">$ {{ course.info_descuento_promocion.precio_final_con_tc_sin_descuento_hibrida}}</span>
                                           <span v-else-if="metodoPago==='TAR' && course.modalidad_seleccionada==='ONLINE' && course.tiene_modalidad_virtual" class="old-price">$ {{ course.info_descuento_promocion.precio_final_con_tc_sin_descuento_virtual}}</span>
                                           <span v-else-if="metodoPago==='TAR'" class="old-price">$ {{ course.info_descuento_promocion.precio_final_con_tc_sin_descuento }}</span>
                                           <span v-else class="old-price">$ {{calcularValorReal(subtotalCarrito, parseFloat(course.info_descuento_promocion.porcentaje_descuento).toFixed(0))}}</span>
                                       </td>
                                       <td class="product-total" style="text-align: right;" v-else-if="course.info_descuento_promocion && !course.info_descuento_promocion.aplica_descuento">
                                          <span v-if="metodoPago==='BAN'" class="amount">$ {{ course.precio }}</span>
                                          <span v-else-if="metodoPago==='TAR' && course.modalidad_seleccionada==='SEMIPRESENCIAL' && course.tiene_modalidad_hibrida" class="amount">$ {{ course.info_descuento_promocion.precio_final_con_tc_sin_descuento_hibrida }}</span>
                                          <span v-else-if="metodoPago==='TAR' && course.modalidad_seleccionada==='ONLINE' && course.tiene_modalidad_virtual" class="amount">$ {{ course.info_descuento_promocion.precio_final_con_tc_sin_descuento_virtual }}</span>
                                          <span v-else-if="metodoPago==='TAR'" class="amount">$ {{ course.info_descuento_promocion.precio_final_con_tc_sin_descuento }}</span>
                                          <span v-else class="amount">$ {{subtotalCarrito}}</span>
                                       </td>
                                    </tr>
                                 </tbody>
                                 <tbody v-else>
                                    <tr class="cart_item" v-for="course in carrito" :key="course.token">
                                       <td class="product-name" style="padding-left: 30px;">
                                          {{ course.name }} {{ course.modalidad_seleccionada }}<strong class="product-quantity"> × 1</strong>
                                           <div class="course__video-discount" v-if="course.info_descuento_promocion && course.info_descuento_promocion.aplica_descuento">
                                                <span>{{ parseFloat(course.info_descuento_promocion.porcentaje_descuento).toFixed(0) }}% Descuento total</span>
                                            </div>
                                       </td>
                                       <td class="course__status product-total" style="text-align: right;" v-if="course.info_descuento_promocion && course.info_descuento_promocion.aplica_descuento">
                                           <span v-if="metodoPago==='BAN' && course.modalidad_seleccionada==='SEMIPRESENCIAL' && course.tiene_modalidad_hibrida" class="green" style="font-size: 14px;">$ {{ course.info_descuento_promocion.precio_postdescuento_hibrida }}</span>
                                           <span v-else-if="metodoPago==='BAN' && course.modalidad_seleccionada==='ONLINE' && course.tiene_modalidad_virtual" class="green" style="font-size: 14px;">$ {{ course.info_descuento_promocion.precio_postdescuento_virtual }}</span>
                                           <span v-else-if="metodoPago==='BAN'" class="green" style="font-size: 14px;">$ {{ course.info_descuento_promocion.precio_postdescuento }}</span>
                                           <span v-else-if="metodoPago==='TAR' && course.modalidad_seleccionada==='SEMIPRESENCIAL' && course.tiene_modalidad_hibrida" class="green" style="font-size: 14px;">$ {{ course.info_descuento_promocion.precio_final_con_tc_hibrida }}</span>
                                           <span v-else-if="metodoPago==='TAR' && course.modalidad_seleccionada==='ONLINE' && course.tiene_modalidad_virtual" class="green" style="font-size: 14px;">$ {{ course.info_descuento_promocion.precio_final_con_tc_virtual }}</span>
                                           <span v-else-if="metodoPago==='TAR'" class="green" style="font-size: 14px;">$ {{ course.info_descuento_promocion.precio_final_con_tc }}</span>
                                           <span v-else class="green" style="font-size: 14px;">$ {{ subtotalCarrito }}</span>
                                           <br>
                                           <span v-if="metodoPago==='BAN'" class="old-price">$ {{ course.precio}}</span>
                                           <span v-else-if="metodoPago==='TAR' && course.modalidad_seleccionada==='SEMIPRESENCIAL' && course.tiene_modalidad_hibrida" class="old-price">$ {{ course.info_descuento_promocion.precio_final_con_tc_sin_descuento_hibrida}}</span>
                                           <span v-else-if="metodoPago==='TAR' && course.modalidad_seleccionada==='ONLINE' && course.tiene_modalidad_virtual" class="old-price">$ {{ course.info_descuento_promocion.precio_final_con_tc_sin_descuento_virtual}}</span>
                                           <span v-else-if="metodoPago==='TAR'" class="old-price">$ {{ course.info_descuento_promocion.precio_final_con_tc_sin_descuento}}</span>
                                           <span v-else class="old-price">$ {{subtotalCarrito}}</span>
                                       </td>
                                       <td class="product-total" style="text-align: right;" v-else-if="course.info_descuento_promocion && !course.info_descuento_promocion.aplica_descuento">
                                          <span v-if="metodoPago==='BAN'" class="amount">$ {{ course.precio }}</span>
                                          <span v-else-if="metodoPago==='TAR' && course.modalidad_seleccionada==='SEMIPRESENCIAL' && course.tiene_modalidad_hibrida" class="amount">$ {{ course.info_descuento_promocion.precio_final_con_tc_sin_descuento_hibrida }}</span>
                                          <span v-else-if="metodoPago==='TAR' && course.modalidad_seleccionada==='ONLINE' && course.tiene_modalidad_virtual" class="amount">$ {{ course.info_descuento_promocion.precio_final_con_tc_sin_descuento_virtual }}</span>
                                          <span v-else-if="metodoPago==='TAR'" class="amount">$ {{ course.info_descuento_promocion.precio_final_con_tc_sin_descuento }}</span>
                                          <span v-else class="amount">$ {{subtotalCarrito}}</span>
                                       </td>
                                    </tr>
                                 </tbody>
                                 <tfoot>
                                    <tr class="cart-subtotal">
                                       <th>Subtotal</th>
                                       <td style="text-align: right;"><span class="amount"><b>$ {{ subtotalCarrito }}</b></span></td>
                                    </tr>
<!--                                    <tr class="shipping">-->
<!--                                          <th>Shipping</th>-->
<!--                                          <td>-->
<!--                                             <ul>-->
<!--                                                <li>-->
<!--                                                   <input type="radio" />-->
<!--                                                   <input type="radio" />-->
<!--                                                   <label>-->
<!--                                                      Flat Rate: <span class="amount">$7.00</span>-->
<!--                                                   </label>-->
<!--                                                </li>-->
<!--                                                <li>-->
<!--                                                   <input type="radio" />-->
<!--                                                   <label>Free Shipping:</label>-->
<!--                                                </li>-->
<!--                                                <li></li>-->
<!--                                             </ul>-->
<!--                                          </td>-->
<!--                                    </tr>-->
                                    <tr class="order-impuestos">
                                       <th>Impuestos (15% IVA)</th>
                                       <td style="text-align: right;"><strong><span class="amount"><b>$ {{ calcularImpuestos }}</b></span></strong>
                                       </td>
                                    </tr>
                                    <tr class="order-total">
                                       <th>Total</th>
                                       <td style="text-align: right;"><strong><span class="amount">$ {{ calcularTotal }}</span></strong>
                                       </td>
                                    </tr>
                                 </tfoot>
                              </table>
                        </div>
                     </div>

                      <div class="coupon-info mb-30">
                          <form action="#">
                              <p class="checkout-coupon">
                                  <input type="text" placeholder="Ingrese código de cupón" style="width: 70%;" v-model="cuponDescuento" maxlength="18"/>
                                  <button type="button" class="e-btn e-btn-border" style="width: 30%;" @click="aplicarCuponDescuento">Aplicar</button>
                              </p>
                             <div class="course__video-discount" v-if="aplicaCuponDescuento && cuponDescuento!==''">
<!--                                  <span>{{ parseFloat(infoCupon.porcentaje_descuento).toFixed(0) }}% Descuento en cursos seleccionados</span>-->
                                  <span>Descuento en cursos/programas seleccionados</span>
                              </div>
                          </form>
                      </div>

                      <div class="your-order">
                        <h3>Método de Pago</h3>
                        <div class="payment-method">
                            <table style="width: 100%;" v-if="this.metodoPago==='FACP' || this.metodoPago==='PAGM'" class="mb-10">
                                <thead>
                                    <tr>
                                       <th class="product-name"><b>Detalles</b></th>
                                       <th class="product-total" style="text-align: right;"><b>Total</b></th>
                                    </tr>
                                 </thead>
                                <tbody v-if="this.metodoPago==='FACP'">
                                    <tr v-if="this.metodoPago === 'FACP'">
                                        <th style="font-weight:normal;">Total a pagar (60%)</th>
                                        <td style="text-align: right;"><strong><span class="amount">$ {{ this.valor_a_pagar }}</span></strong>
                                        </td>
                                    </tr>
                                    <tr v-if="this.metodoPago === 'FACP'">
                                        <th style="font-weight:normal;">Valor pendiente a pagar (40%)</th>
                                        <td style="text-align: right;"><strong><span class="amount">$ {{ this.valor_pendiente }}</span></strong>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-if="this.metodoPago === 'PAGM'">
                                        <th style="font-weight:normal;">Total transferencia ({{this.transferenciaPercentage}}%)</th>
                                        <td style="text-align: right;"><strong><span class="amount">$ {{ parseFloat(calcularTotal - (parseFloat(calcularTotal * this.tarjetaPercentage/100).toFixed(2))).toFixed(2) }}</span></strong>
                                        </td>
                                    </tr>
                                    <tr v-if="this.metodoPago === 'PAGM'">
                                        <th style="font-weight:normal;">Total tarjeta ({{this.tarjetaPercentage}}%)</th>
                                        <td style="text-align: right;"><strong><span class="amount">$ {{ parseFloat(calcularTotal * this.tarjetaPercentage/100).toFixed(2) }}</span></strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <hr v-if="this.metodoPago==='FACP' || this.metodoPago==='PAGM'"/>
                            <b-form-select v-model="metodoPago">
                                <b-form-select-option value="BAN">Transferencia o depósito bancario</b-form-select-option>
                                <b-form-select-option value="TAR">Tarjeta de Crédito</b-form-select-option>
                                <b-form-select-option value="PAGM">Pago mixto</b-form-select-option>
                                <b-form-select-option value="FACP">Facilidad de pago</b-form-select-option>
                            </b-form-select>
                            <div class="text-justify mt-20" v-if="metodoPago==='FACP'">
                                Este método de pago consiste en pagar el 60% mediante transferencia bancaria o tarjeta de crédito. El 40% queda pendiente de pagar durante la duración del curso.
                                <hr v-if="this.metodoPago==='FACP'"/>
                                <b-form-select v-model="metodoFacilidadPago" class="mt-10">
                                    <b-form-select-option value="BAN">Transferencia o depósito bancario</b-form-select-option>
                                    <b-form-select-option value="TAR">Tarjeta de Crédito</b-form-select-option>
                                </b-form-select>
                            </div>
                            <div class="text-justify mt-20 mb-10" v-if="metodoPago==='PAGM' ">
                                El pago mixto consiste en pagar el valor total en dos partes: una parte con transferencia/depósito bancario y otra con tarjeta crédito.
                            </div>
                            <hr v-if="this.metodoPago==='PAGM'"/>
                            <div class="text-justify mt-20 mb-20" v-if="metodoPago === 'BAN' || metodoPago === 'PAGM' || metodoFacilidadPago === 'BAN' && metodoPago !== 'TAR'">
                            <!-- Para completar el pago, siga las instrucciones que se le enviarán a su correo de facturación. -->
                            <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;" v-if="metodoPago === 'PAGM'">
                                <label for="transferenciaPercentage" style="font-size: 1.2em; font-weight: bold; color: black;">
                                    Transferencia o depósito bancario:
                                </label>
                                <div style="display: flex; align-items: center; position: relative; width: 150px;">
                                    <input type="number" id="transferenciaPercentage" style="text-align: right; padding-right: 1.5em; width: 100%; border: 1px solid #ced4da;" v-model="transferenciaPercentage" @input="updatePorcentajes('transferencia')" min="0" max="100">
                                    <span style="position: absolute; right: 0.5em; color: black; font-weight: bold;">%</span>
                                </div>
                            </div>

                            A continuación se muestra información de la cuenta a la que debe realizar la transferencia o depósito:
                            <div class="text-center mt-4">
                                <b>Banco Pichincha Cuenta Corriente #2100287513</b><br>
                                <b>Compañía: </b>Lógiga Escuela de Negocios S.A.<br>
                                <b>R.U.C.: </b>0993374041001<br>
                                <b>Correo: </b>contabilidad@logigacorp.com<br>
                            </div>

                            <div class="text-center mt-2">
                                <a href="#">
                                    <img src="../../assets/img/course/payment/banco-pichincha.png" alt="banco pichincha" height="30" width="120">
                                    <img src="../../assets/img/course/payment/cash-payment.png" alt="cash" height="40" width="70">
                                </a>
                            </div>

                            <br><br>
                            <p>Una vez realizado el pago, suba el comprobante aquí. Solo se permiten archivos PDF e imágenes JPG y PNG.</p>
                            <div class="mb-40 text-center">
                                <label for="upload_file" class="custom-file-upload">
                                    <i class="fas fa-upload"></i> Subir archivo
                                </label>
                                <input id="upload_file" type="file" accept=".jpg,.png,.pdf" @change="onUploadFile" multiple>
                                <!-- <input id="upload_file" type="file" accept=".jpg,.png,.pdf" @change="onUploadFile" multiple> -->
                                <div>
                                    <div v-if="files_evidencias.length > 0" class="files mt-10">
                                        <div class="file-item" v-for="(file, index) in files_evidencias" :key="index">
                                            <span>{{ file.name }}</span>
                                            <span class="delete-file" @click="handleClickDeleteFile(index)">Eliminar</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr v-if="this.metodoPago==='PAGM'"/>
                        
                        <div class="text-justify mt-20 mb-20" v-if="metodoPago === 'TAR' || metodoPago === 'PAGM' || metodoFacilidadPago === 'TAR'">
                            <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;" v-if="metodoPago === 'PAGM'">
                                <label for="tarjetaPercentage" style="font-size: 1.2em; font-weight: bold; color: black;">
                                    Tarjeta de crédito:
                                </label>
                                <div style="display: flex; align-items: center; position: relative; width: 150px;">
                                    <input type="number" id="tarjetaPercentage" style="text-align: right; padding-right: 1.5em; width: 100%; border: 1px solid #ced4da;" v-model="tarjetaPercentage" @input="updatePorcentajes('tarjeta')" min="0" max="100">
                                    <span style="position: absolute; right: 0.5em; color: black; font-weight: bold;">%</span>
                                </div>
                            </div>

                            Realice el pago online mediante tarjeta de crédito <br>
                            <div class="text-center mt-2">
                                <a href="#">
                                    <img src="../../assets/img/course/payment/mastercard-card.png" alt="mastercard card" height="36">
                                    <img src="../../assets/img/course/payment/visa-card.jpg" alt="visa card" height="36" width="50">
                                    <img src="../../assets/img/course/payment/american-card.png" alt="american express card" height="36">
                                    <img src="../../assets/img/course/payment/diners-card.jpg" alt="diners club card" height="36" width="100">
                                </a>
                            </div>

                            <div class="mb-40 mt-40 text-left">
                                <ul>
                                    <li>
                                        <input type="radio" name="tipo_pago_tarjeta" id="CORR" value="CORR" v-model="tipoPagoTarjeta"/>
                                        <label for="CORR">
                                            Pago corriente
                                        </label>
                                    </li>
                                    <li>
                                        <input type="radio" name="tipo_pago_tarjeta" id="DIFI" value="DIFI" v-model="tipoPagoTarjeta"/>
                                        <label for="DIFI">
                                            Pago diferido con intereses
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>

<!--                           <div class="accordion" id="checkoutAccordion">-->
<!--                              <div class="accordion-item">-->
<!--                                 <h2 class="accordion-header" id="paymentTwo">-->
<!--                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#payment" :aria-expanded="metodoPago==='BAN'" aria-controls="payment" @click="cambiarMetodoPago('BAN')">-->
<!--                                    Transferencia Bancaria-->
<!--                                    </button>-->
<!--                                 </h2>-->
<!--                                 <div id="payment" class="accordion-collapse collapse show" aria-labelledby="paymentTwo" data-bs-parent="#checkoutAccordion">-->
<!--                                    <div class="accordion-body text-justify">-->
<!--                                      Para completar el pago, realice una transferencia bancaria a la cuenta corriente #2100287513 del Banco Pichincha.-->
<!--                                      Posteriormente envíe un mensaje vía correo electrónico con sus datos (Nombres y documento de identidad) adjuntando el comprobante de pago al siguiente correo: <a href="mailto:contabilidad@logigacorp.com" style="color:#0F80C1">contabilidad@logigacorp.com</a>-->
<!--                                    </div>-->
<!--                                 </div>-->
<!--                              </div>-->
<!--                              <div class="accordion-item">-->
<!--                                 <h2 class="accordion-header" id="checkoutOne">-->
<!--                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#bankOne" :aria-expanded="metodoPago==='TAR'" aria-controls="bankOne" @click="cambiarMetodoPago('TAR')">-->
<!--                                    Tarjeta de Crédito-->
<!--                                    </button>-->
<!--                                 </h2>-->
<!--                                 <div id="bankOne" class="accordion-collapse collapse" aria-labelledby="checkoutOne" data-bs-parent="#checkoutAccordion">-->
<!--                                     <div class="accordion-body text-justify mb-10">-->
<!--                                        Realice el pago online mediante tarjeta de crédito <br>-->
<!--                                        <div class="text-center mt-2">-->
<!--                                            <a href="#">-->
<!--                                                <img src="../../assets/img/course/payment/payment-1.png" alt="">-->
<!--                                            </a>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--&lt;!&ndash;                                    <div class="accordion-body">&ndash;&gt;-->
<!--&lt;!&ndash;                                        <form data-vv-scope="nueva_tarjeta">&ndash;&gt;-->
<!--&lt;!&ndash;                                            <vue-paycard :valueFields="cardValueFields" :inputFields="nuevaTarjetaInputs" :isCardNumberMasked="isCardNumberMasked" ref="myVuePaycard"/>&ndash;&gt;-->
<!--&lt;!&ndash;                                            <div class="row mt-50">&ndash;&gt;-->
<!--&lt;!&ndash;                                                <div class="col-12 checkout-form-list">&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <Field name="cardName" v-slot="{ field, errors, errorMessage }">&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <label>Name<span class="required">*</span></label>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <input&ndash;&gt;-->
<!--&lt;!&ndash;                                                            data-card-field&ndash;&gt;-->
<!--&lt;!&ndash;                                                            :id="nuevaTarjetaInputs.cardName"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            type="text"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            v-model="cardValueFields.cardName"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            name="cardName"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            placeholder="Ingrese el nombre del titular..."&ndash;&gt;-->
<!--&lt;!&ndash;                                                            v-bind="field" class="form-control" :class="[{'is-invalid': errors.length>0}]"&ndash;&gt;-->
<!--&lt;!&ndash;                                                        />&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <span class="text-danger">{{ errorMessage }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </Field>&ndash;&gt;-->
<!--&lt;!&ndash;                                                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                <div class="col-12 checkout-form-list">&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <Field name="cardNumber" v-slot="{ field, errors, errorMessage }">&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <label>Card Number <span class="required">*</span></label>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <input&ndash;&gt;-->
<!--&lt;!&ndash;                                                            data-card-field&ndash;&gt;-->
<!--&lt;!&ndash;                                                            :id="nuevaTarjetaInputs.cardNumber"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            type="text"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            v-model="cardValueFields.cardNumber"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            @input="changeCardNumber"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            maxlength="19"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            name="cardNumber"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            placeholder="xxxx xxxx xxxx xxxx"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            v-bind="field" class="form-control" :class="[{'is-invalid': errors.length>0}]"&ndash;&gt;-->
<!--&lt;!&ndash;                                                        />&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <span class="text-danger">{{ errorMessage }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </Field>&ndash;&gt;-->
<!--&lt;!&ndash;                                                </div>&ndash;&gt;-->

<!--&lt;!&ndash;                                                    <div class="col-md-4 col-sm-12 checkout-form-list">&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <Field name="cardMonth" v-slot="{ field, errors, errorMessage }">&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <label>Card Month <span class="required">*</span></label>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input&ndash;&gt;-->
<!--&lt;!&ndash;                                                                data-card-field&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :id="nuevaTarjetaInputs.cardMonth"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                type="text"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="cardValueFields.cardMonth"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="cardMonth"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                placeholder="MM"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-bind="field" class="form-control" :class="[{'is-invalid': errors.length>0}]"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            />&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <span class="text-danger">{{ errorMessage }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </Field>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <div class="col-md-4 col-sm-12 checkout-form-list">&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <Field name="cardYear" v-slot="{ field, errors, errorMessage }">&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <label>Card Year <span class="required">*</span></label>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input&ndash;&gt;-->
<!--&lt;!&ndash;                                                                data-card-field&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :id="nuevaTarjetaInputs.cardYear"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                type="text"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="cardValueFields.cardYear"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="cardYear"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                placeholder="YYYY"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-bind="field" class="form-control" :class="[{'is-invalid': errors.length>0}]"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            />&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <span class="text-danger">{{ errorMessage }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </Field>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <div class="col-md-4 col-sm-12 checkout-form-list">&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <Field name="cardCvv" v-slot="{ field, errors, errorMessage }">&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <label>CVV <span class="required">*</span></label>&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <input&ndash;&gt;-->
<!--&lt;!&ndash;                                                                data-card-field&ndash;&gt;-->
<!--&lt;!&ndash;                                                                :id="nuevaTarjetaInputs.cardCvv"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                type="text"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-model="cardValueFields.cardCvv"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                name="cardCvv"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                placeholder="xxxx"&ndash;&gt;-->
<!--&lt;!&ndash;                                                                v-bind="field" class="form-control" :class="[{'is-invalid': errors.length>0}]"&ndash;&gt;-->
<!--&lt;!&ndash;                                                            />&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <span class="text-danger">{{ errorMessage }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </Field>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                        </form>&ndash;&gt;-->
<!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--                                 </div>-->
<!--                              </div>-->
<!--&lt;!&ndash;                              <div class="accordion-item">&ndash;&gt;-->
<!--&lt;!&ndash;                                 <h2 class="accordion-header" id="paypalThree">&ndash;&gt;-->
<!--&lt;!&ndash;                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#paypal" aria-expanded="false" aria-controls="paypal">&ndash;&gt;-->
<!--&lt;!&ndash;                                    PayPal&ndash;&gt;-->
<!--&lt;!&ndash;                                    </button>&ndash;&gt;-->
<!--&lt;!&ndash;                                 </h2>&ndash;&gt;-->
<!--&lt;!&ndash;                                 <div id="paypal" class="accordion-collapse collapse" aria-labelledby="paypalThree" data-bs-parent="#checkoutAccordion">&ndash;&gt;-->
<!--&lt;!&ndash;                                    <div class="accordion-body">&ndash;&gt;-->
<!--&lt;!&ndash;                                    Pay via PayPal; you can pay with your credit card if you don’t have a&ndash;&gt;-->
<!--&lt;!&ndash;                                    PayPal account.&ndash;&gt;-->
<!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                 </div>&ndash;&gt;-->
<!--&lt;!&ndash;                              </div>&ndash;&gt;-->
<!--                            </div>-->
                            <div class="order-button-payment mt-10">
                               <button type="submit" class="e-btn e-btn-border">Pagar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         </Form>
      </div>
      <div v-else class="text-center">
          No hay registros para mostrar
      </div>
   </section>
</template>

<script>
// import CouponArea from './CouponArea.vue';
import { useCookies } from "vue3-cookies";
// import { VuePaycard } from "vue-paycard";
// import { Validator } from 'vee-validate';
// import es from 'vee-validate/dist/locale/es'
// Validator.localize("es", es);
import { Field, Form } from 'vee-validate';
import axios from "@/axios";
// import rutasjson from "@/assets/utils/rutas.json";

import { obtenerTipoPersonaFacturacion } from "@/assets/utils/actions.js";

export default {
   name:'CheckoutArea',
   components:{
      // CouponArea,
      // VuePaycard,
      Form,
      Field
   },
    setup() {
      const { cookies } = useCookies();
      return { cookies };
    },
   data () {
      return {
        mostrarLoader: false,
        showAcc:false,
        shipBox:false,
        // carrito: this.$store.getters.getCarrito,
        carritoConCupon: [],
        aplicaCuponDescuento: false,
        cuponDescuento: '',
        infoCupon: {},
        porcentajeImpuestos: 15,

        datosFacturacion: {
            nombres: '',
            apellidos: '',
            documento_identidad: '',
            tipo_persona: '',
            email: '',
            compania: '',
            pais: 'Ecuador',
            direccion: '',
            ciudad: '',
            telefono: '',
            terminos_condiciones: '',
            asesor: '',
        },
        cuentaCanvas: {
            // usuarioTieneCuenta: false,
            usuario: '',
            // password: '',
        },

        metodoPago: 'BAN',
        cardValueFields: {
            cardName: '',
            cardNumber: '',
            cardMonth: '',
            cardYear: '',
            cardCvv: '',
        },
        nuevaTarjetaInputs: {
            cardName: 'v-card-name',
            cardNumber: 'v-card-number',
            cardMonth: 'v-card-month',
            cardYear: 'v-card-year',
            cardCvv: 'v-card-cvv'
        },
        isCardNumberMasked: false,

        checkoutSchemaV1: {
            nombres: 'required',
            apellidos: 'required',
            documento_identidad: 'required||isCedula||isRuc',
            email: 'required',
            direccion: 'required',
            ciudad: 'required',
            // telefono: 'required',
            terminos_condiciones: 'terminosCondiciones',

            usuario: 'required',
        },

        // checkoutSchemaV2: {
        //     nombres: 'required',
        //     apellidos: 'required',
        //     documento_identidad: 'required',
        //     email: 'required',
        //     direccion: 'required',
        //     ciudad: 'required',
        //     telefono: 'required',
        //     terminos_condiciones: 'terminosCondiciones',
        //
        //     usuario: 'required',
        //
        //     // cardName: 'required',
        //     // cardNumber: 'required',
        //     // cardMonth: 'required',
        //     // cardYear: 'required',
        //     // cardCvv: 'required',
        // },

        // credenciales_paymentez: {},
        // paymentez_status_add_card_details: {},
        prefix_dev_reference_curso: '',
        paymentez_init_reference: '',
        env_mode: '',
        porcentaje_cobro_tc: 0.08,
        files_evidencias: [],
        limite_file: 1024,
        orden_token: '',

        transferenciaPercentage: 50,
        tarjetaPercentage: 50,
        metodoFacilidadPago: 'BAN',

        valor_a_pagar: 0,
        valor_pendiente: 0,
        porcentaje_facilidad_pago: 0.6,
        tipoPagoTarjeta: 'CORR',
    }
   },
    watch: {
      metodoPago(newValue) {
        if(newValue=='BAN'){
           // eslint-disable-next-line
           // this.course.precio = this.course.precio_modalidad_hibrida
          this.aplicaCuponDescuento = false
          this.cuponDescuento = ''

        }
        else if(newValue=='TAR'){
           // eslint-disable-next-line
           // this.course.precio = this.course.precio_modalidad_virtual
          this.aplicaCuponDescuento = false
          this.cuponDescuento = ''

          // let carrito = JSON.parse(this.cookies.get("carritoLogiga"))
          this.carrito.forEach((course) => {
             let precioFinalCurso = 0.00
            let precioFinalSinDescuento = parseFloat(course.precio)
             if(course.info_descuento_promocion && course.info_descuento_promocion['aplica_descuento']){
                precioFinalCurso += parseFloat(course.info_descuento_promocion['precio_postdescuento'])
             }
             else{
                precioFinalCurso += parseFloat(course.precio)
                // precioFinalSinDescuento += parseFloat(course.precio)
             }

             course.info_descuento_promocion.precio_final_con_tc = ((precioFinalCurso * this.porcentaje_cobro_tc) + precioFinalCurso).toFixed(2)
             course.info_descuento_promocion.precio_final_con_tc_sin_descuento = ((precioFinalSinDescuento * this.porcentaje_cobro_tc) + precioFinalSinDescuento).toFixed(2)

            // console.log("calculos watch")
            // console.log(course.info_descuento_promocion.precio_final_con_tc)
            // console.log(course.info_descuento_promocion.precio_final_con_tc_sin_descuento)
            if(course.modalidad_seleccionada ==='SEMIPRESENCIAL' && course.tiene_modalidad_hibrida){
               course.info_descuento_promocion.precio_final_con_tc_hibrida = course.info_descuento_promocion.precio_final_con_tc
               course.info_descuento_promocion.precio_final_con_tc_sin_descuento_hibrida = course.info_descuento_promocion.precio_final_con_tc_sin_descuento
            }

            else if(course.modalidad_seleccionada ==='ONLINE' && course.tiene_modalidad_virtual){
               course.info_descuento_promocion.precio_final_con_tc_virtual = course.info_descuento_promocion.precio_final_con_tc
               course.info_descuento_promocion.precio_final_con_tc_sin_descuento_virtual = course.info_descuento_promocion.precio_final_con_tc_sin_descuento
            }
          })

        }
        else {
            this.aplicaCuponDescuento = false
            this.cuponDescuento = ''
        }
      }
    },
   methods:{
        calcularValorReal(valorConDescuento, porcentajeDescuento){
            return (valorConDescuento / (1 - (porcentajeDescuento / 100))).toFixed(2);
        },
        updatePorcentajes(campo) {
            if (campo === 'transferencia') {
                this.transferenciaPercentage = this.validatePercentage(this.clamp(this.transferenciaPercentage));
                this.tarjetaPercentage = 100 - this.transferenciaPercentage;
            } else if (campo === 'tarjeta') {
                this.tarjetaPercentage = this.validatePercentage(this.clamp(this.tarjetaPercentage));
                this.transferenciaPercentage = 100 - this.tarjetaPercentage;
            }
        },
        validatePercentage(value) {
            if (value <= 0) {
                return 1;
            }
            if (value >= 100) {
                return 99;
            }
            return value;
        },
        clamp(value) {
            return Math.max(0, Math.min(100, value));
        },
        //Metodos para subir comprobante transferencia
        onUploadFile(event){
            const selectedFiles = event.target.files;
            for (const file of selectedFiles){
                if (Math.round((file.size / this.limite_file)) > this.limite_file) {
                    this.$swal({
                      title: 'Error!',
                      text: 'Tamaño de archivo no permitido. El peso máximo es de 1MB.',
                      icon: 'error',
                      confirmButtonColor: '#0F80C1',
                      confirmButtonText: 'Aceptar',
                    })
                    return false
                }
                else if(this.checkFileExtension(file.name)){
                    this.$swal({
                      title: 'Error!',
                      text: 'Formato de archivo no permitido. Solo se acepta JPG y PNG.',
                      icon: 'error',
                      confirmButtonColor: '#0F80C1',
                      confirmButtonText: 'Aceptar',
                    })
                    return false
                }
                else{
                    this.createBase64File(file);
                }
            }
            document.getElementById("upload_file").value = '';
        },
        checkFileExtension(filename){
            filename = filename.toLowerCase();
            const ext = filename.split('.').pop();
            if(ext!='png' && ext!='jpg' && ext!='jpeg' && ext!='pdf'){
                return true
            }
            return false
        },
        createBase64File(fileObject){
            const reader = new FileReader();
            reader.onload = (e) => {
                let newFile = {'name': fileObject.name, 'data': e.target.result}
                this.files_evidencias.push(newFile)
            };
            reader.readAsDataURL(fileObject);
        },
        handleClickDeleteFile(index){
            this.files_evidencias.splice(index, 1);
        },
      showCreateAcc () {
         return this.showAcc = !this.showAcc
      },
      handleShipBox () {
         return this.shipBox = !this.shipBox
      },
      changeCardNumber(e){
          var cardNumber = e.target.value;

          // Do not allow users to write invalid characters
          var formattedCardNumber = cardNumber.replace(/[^\d]/g, "");
          formattedCardNumber = formattedCardNumber.substring(0, 16);

          // Split the card number is groups of 4
          var cardNumberSections = formattedCardNumber.match(/\d{1,4}/g);
          if (cardNumberSections !== null) {
              formattedCardNumber = cardNumberSections.join(' ');
          }

          // If the formmattedCardNumber is different to what is shown, change the value
          if (cardNumber !== formattedCardNumber) {
              this.valueFields.cardNumber = formattedCardNumber;
          }
      },
      cambiarMetodoPago(metodo){
          this.metodoPago = metodo
      },
       checkSubmitCheckoutForm(){
        // if(this.metodoPago === 'BAN' || this.metodoPago === 'PAGM' || (this.metodoPago==='FACP' && this.metodoFacilidadPago==='BAN')){
        //     if(this.files_evidencias.length <= 0){
        //         this.faltaComprobantes()
        //     } else {
        //         this.finalizarCompra()
        //     }
        // } else {
        //   this.finalizarCompra()
        // }
        this.finalizarCompra()
       },
       finalizarCompra(){
        this.$swal({
            title: 'Finalizar Compra',
            text: '¿Está seguro de finalizar la compra?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0F80C1',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            }).then(result => {
              if (result.value) {
                  this.obtenerTipoPersona()
                  this.openLoadingInDiv()
                  let carrito =  JSON.parse(localStorage.getItem("carritoLogiga"))
                  // let carrito = JSON.parse(this.cookies.get("carritoLogiga"))
                  if(this.aplicaCuponDescuento){
                      carrito = this.carritoConCupon
                  }
                  let datosOrden = {
                      total: this.calcularTotal,
                      subtotal: this.subtotalCarrito,
                      impuestos: this.calcularImpuestos,
                      detalle_orden: carrito
                  }
                  axios.post('api/orden/validar-info-pago/',{
                      datosOrden: datosOrden,
                      cupon: this.cuponDescuento
                  }).then(response => {
                      if (response.data.status == 'success'){
                          if(response.data.data.pago_normal){
                              //si es pago normal
                              this.submitCheckoutForm()
                          }
                          else if(response.data.data.pago_descuento){
                              //si tiene 100% de descuento
                              //matricular directo y guardar orden, datos de factura
                              this.procesarPagoCarrito(null, response.data.data.descuento_100)
                          }
                      }else{
                          this.$swal({
                            title: 'Error!',
                            text: response.data.message,
                            icon: 'error',
                            confirmButtonColor: '#0F80C1',
                            confirmButtonText: 'Aceptar',
                          })
                      }
                      this.closeLoadingInDiv()
                  });
              }
            });
       },
      submitCheckoutForm(){
          // console.log("submitCheckoutForm ------------------")
          if(this.metodoPago==='TAR'){
            this.makeCheckoutPaymentez(1)
          }
          else if(this.metodoPago==='BAN'){
            if(this.files_evidencias.length <= 0){
                this.faltaComprobantes()
            }
            else{
                this.procesarPagoCarrito()
            }
          }
          else if(this.metodoPago==='FACP'){
            if(this.metodoFacilidadPago === 'TAR'){
                this.makeCheckoutPaymentez(this.porcentaje_facilidad_pago)
            }
            else{
                if(this.files_evidencias.length <= 0){
                    this.faltaComprobantes()
                }
                else{
                    this.procesarPagoCarrito()
                }
            }
          }
          else if(this.metodoPago==='PAGM'){
            if(this.files_evidencias.length <= 0){
                this.faltaComprobantes()
            }
            else{
                this.makeCheckoutPaymentez(this.tarjetaPercentage/100)
            }
          }
      },
      faltaComprobantes(){
        this.$swal({
            title: 'Error!',
            text: 'No ha subido ningún comprobante.',
            icon: 'error',
            confirmButtonColor: '#0F80C1',
            confirmButtonText: 'Aceptar',
        })
      },
      obtenerTipoPersona(){
          const cedulaRuc = this.datosFacturacion.documento_identidad
          this.datosFacturacion.tipo_persona = obtenerTipoPersonaFacturacion(cedulaRuc)
      },

      openLoadingInDiv(){
          this.mostrarLoader = true
      },
      closeLoadingInDiv(){
          this.mostrarLoader = false
      },

      obtenerConfigPaymentez(){
          this.openLoadingInDiv()
          axios.post('api/orden/get-config-paymentez/').then(response => {
              // console.log("obtener config paymentez")
              // console.log(response.data.data)
              if (response.data.status == 'success'){
                  // this.credenciales_paymentez = response.data.data.credenciales_paymentez;
                  // this.paymentez_status_add_card_details = response.data.data.paymentez_status_add_card_details;
                  this.prefix_dev_reference_curso = response.data.data.prefix_dev_reference_curso
                  this.env_mode = response.data.data.env_mode
              }else{
                  this.$swal({
                    title: 'Error!',
                    text: response.data.message,
                    icon: 'error',
                    confirmButtonColor: '#0F80C1',
                    confirmButtonText: 'Aceptar',
                  })
              }
              this.closeLoadingInDiv()
          });
      },

      inicializarPaymentezPaycard(){
          let thisComponent = this
          // eslint-disable-next-line
          this.paymentCheckout = new PaymentCheckout.modal({
              // client_app_code: thisComponent.credenciales_paymentez.client_app_code, // Client Credentials
              // client_app_key: thisComponent.credenciales_paymentez.client_app_key, // Client Credentials
              locale: 'es', // User's preferred language (es, en, pt). English will be used by default.
              env_mode: 'prod', // `prod`, `stg`, `local` to change environment. Default is `stg`


              onResponse: async function (response) { // The callback to invoke when the Checkout process is completed
                // console.log("++++++++++++++++++++++++++++++++++++++++++")
                // console.log(response)
                // console.log("++++++++++++++++++++++++++++++++++++++++++")
                  if(response.transaction.status_detail == 3){
                      thisComponent.openLoadingInDiv()
                      await thisComponent.procesarPagoCarrito(response)
                  }
                  else{
                      this.$swal({
                          title: 'Error!',
                          text: 'Ha ocurrido un error con su pago, vuelva a intentarlo en un momento. ERROR: ' + String(response.transaction.current_status) + '. ' + String(response.transaction.message),
                          icon: 'error',
                          confirmButtonColor: '#0F80C1',
                          confirmButtonText: 'Aceptar',
                      })
                  }
              }
          });
      },

      makeCheckoutPaymentez(porcentaje){
          this.openLoadingInDiv()
        let order = {
            amount: parseFloat((parseFloat(this.calcularTotal) * porcentaje).toFixed(2)),
            description: 'Pago por compra de cursos o programas',
            vat: parseFloat((parseFloat(this.calcularImpuestos) * porcentaje).toFixed(2)),
            taxable_amount: parseFloat((parseFloat(this.subtotalCarrito) * porcentaje).toFixed(2)),
            tax_percentage: parseFloat((parseFloat(String(this.porcentajeImpuestos)).toFixed(2))),
            dev_reference: this.prefix_dev_reference_curso + String(Date.now()) + '_' + this.datosFacturacion.email,
        }

          let user = {
              id: this.datosFacturacion.email,
              email: this.datosFacturacion.email
          }

          axios.post('api/orden/generar-checkout-reference/',{
              order: order,
              user: user,
              tipoPagoTarjeta: this.tipoPagoTarjeta
          }).then(
              response => {
                  // this.closeLoadingInDiv()
                  if(response.data.status == 'success'){
                      this.paymentCheckout.open({
                          reference: response.data.data // reference received for Payment Gateway
                      });
                  }
                  else{
                      this.$swal({
                          title: 'Error!',
                          text: response.data.message,
                          icon: 'error',
                          confirmButtonColor: '#0F80C1',
                          confirmButtonText: 'Aceptar',
                      })
                  }
              })

          // this.paymentCheckout.open({
          //     user_id: this.datosFacturacion.email, //id
          //     user_email: this.datosFacturacion.email, //optional
          //     user_phone: this.datosFacturacion.telefono, //optional
          //     order_description: 'Pago por compra de curso/s', //este es el token del tramite
          //     order_amount: this.calcularTotal,
          //     order_vat: this.calcularImpuestos,
          //     order_reference: this.prefix_dev_reference_curso + String(Date.now()) + '_' + this.datosFacturacion.email, //se envia como dev_reference "CURSO_" + <timestamp> + "_" + <email_facturacion>
          //     order_taxable_amount: this.subtotalCarrito, // optional: Only available for Datafast (Equador). The taxable amount, if it is zero, it is calculated on the total. Format: Decimal with two fraction digits.
          //     order_tax_percentage: this.porcentajeImpuestos, // optional: Only available for Datafast (Equador). The tax percentage to be applied to this order.
          // });
      },

      procesarPagoCarrito(dataPago=null, descuento100=null){
        // if(this.files_evidencias.length > 0) {
          // this.openLoadingInDiv()
          let carrito =  JSON.parse(localStorage.getItem("carritoLogiga"))
          // let carrito = JSON.parse(this.cookies.get("carritoLogiga"))
          if(this.aplicaCuponDescuento){
              carrito = this.carritoConCupon
          }

          let datosOrden = {
              total: this.calcularTotal,
              subtotal: this.subtotalCarrito,
              impuestos: this.calcularImpuestos,
              detalle_orden: carrito
          }

          let datosPagoMixto = {
            "pagos": [
                {
                    "metodo": "BAN",
                    "porcentaje": this.transferenciaPercentage,
                    "total": parseFloat(this.calcularTotal - parseFloat(this.calcularTotal * (this.tarjetaPercentage/100)).toFixed(2)).toFixed(2),
                    "dataPago": this.files_evidencias
                },
                {
                    "metodo": "TAR",
                    "porcentaje": this.tarjetaPercentage,
                    "total": parseFloat(this.calcularTotal * (this.tarjetaPercentage/100)).toFixed(2),
                    "dataPago": dataPago
                }
            ]
          }

          let datosFacilidadPago = {
            "pagos": [
                {
                    "metodo": this.metodoFacilidadPago,
                    "porcentaje": this.porcentaje_facilidad_pago * 100,
                    "total": parseFloat(this.calcularTotal * this.porcentaje_facilidad_pago).toFixed(2),
                    "dataPago": this.metodoFacilidadPago === 'TAR' ? dataPago : this.files_evidencias
                },
                {
                    "metodo": "PEN",
                    "porcentaje": (1 - this.porcentaje_facilidad_pago) * 100,
                    "total": parseFloat(this.calcularTotal - parseFloat(this.calcularTotal * this.porcentaje_facilidad_pago).toFixed(2)).toFixed(2),
                }
            ]
          }
          axios.post('api/orden/procesar-pago-carrito/',{
              datosPagoMixto: this.metodoPago === 'PAGM' ? datosPagoMixto : null,
              datosFacilidadPago: this.metodoPago === 'FACP' ? datosFacilidadPago : null,
              dataPago: dataPago,
              descuento100: descuento100,
              datosFacturacion: this.datosFacturacion,
              datosOrden: datosOrden,
              metodoPago: this.metodoPago,
              cuentaCanvas: this.cuentaCanvas,
              files_evidencias: this.files_evidencias,
              tipoPagoTarjeta: this.tipoPagoTarjeta
          }).then(
              response => {
                  if(response.data.status == 'success'){
                    this.$swal({
                        title: 'Compra finalizada',
                        text: response.data.message,
                        icon: 'success',
                        showCancelButton: true,
                        confirmButtonColor: '#0F80C1',
                        confirmButtonText: 'Aceptar',
                        cancelButtonText: 'Cancelar',
                    }).then(result => {
                        console.log(result)
                        //limpiar carrito y actualizar pagina
                        localStorage.setItem("carritoLogiga", JSON.stringify([]))
                        // this.cookies.set("carritoLogiga", JSON.stringify([]), '30d', '', rutasjson.domain)
                        this.$store.commit('setCarrito', [])

                        let subtotal = 0.00
                        localStorage.setItem("carritoSubtotal", subtotal.toFixed(2))
                        // this.cookies.set("carritoSubtotal", subtotal.toFixed(2), '30d', '', rutasjson.domain)
                        this.$store.commit('setSubtotal', subtotal)
                        this.$router.push("/")
                    })
                  }
                  else{
                    this.$swal({
                        title: 'Error!',
                        text: response.data.message,
                        icon: 'error',
                        confirmButtonColor: '#0F80C1',
                        confirmButtonText: 'Aceptar',
                    })
                }
                this.closeLoadingInDiv()
              }
          )
        // }
        // else{
        //     this.$swal({
        //     title: 'Error!',
        //     text: 'No ha subido ningún archivo.',
        //     icon: 'error',
        //     confirmButtonColor: '#0F80C1',
        //     confirmButtonText: 'Aceptar',
        //     })
        // }
      },
      aplicarCuponDescuento(){
          this.openLoadingInDiv()
          axios.post('api/cupon/aplicar-cupon/',{
              cupon: this.cuponDescuento,
              detalle_orden: this.carrito,
          }).then(
              response => {
                  if(response.data.status == 'success'){
                    //   console.log(response.data)
                      if(response.data.data.info_cupon !== null){
                          this.aplicaCuponDescuento = true
                      }
                      else{
                          this.aplicaCuponDescuento = false
                      }
                      this.infoCupon = response.data.data.info_cupon
                      this.carritoConCupon = response.data.data.detalle_orden

                      this.carrito.forEach((course) => {
                         let precioFinalCurso = 0.00
                        let precioFinalSinDescuento = parseFloat(course.precio)
                         if(course.info_descuento_promocion && course.info_descuento_promocion['aplica_descuento']){
                            precioFinalCurso += parseFloat(course.info_descuento_promocion['precio_postdescuento'])
                         }
                         else{
                            precioFinalCurso += parseFloat(course.precio)
                            // precioFinalSinDescuento += parseFloat(course.precio)
                         }

                         course.info_descuento_promocion.precio_final_con_tc = ((precioFinalCurso * this.porcentaje_cobro_tc) + precioFinalCurso).toFixed(2)
                         course.info_descuento_promocion.precio_final_con_tc_sin_descuento = ((precioFinalSinDescuento * this.porcentaje_cobro_tc) + precioFinalSinDescuento).toFixed(2)

                        // console.log("calculos watch")
                        // console.log(course.info_descuento_promocion.precio_final_con_tc)
                        // console.log(course.info_descuento_promocion.precio_final_con_tc_sin_descuento)
                        if(course.modalidad_seleccionada ==='SEMIPRESENCIAL' && course.tiene_modalidad_hibrida){
                           course.info_descuento_promocion.precio_final_con_tc_hibrida = course.info_descuento_promocion.precio_final_con_tc
                           course.info_descuento_promocion.precio_final_con_tc_sin_descuento_hibrida = course.info_descuento_promocion.precio_final_con_tc_sin_descuento
                        }

                        else if(course.modalidad_seleccionada ==='ONLINE' && course.tiene_modalidad_virtual){
                           course.info_descuento_promocion.precio_final_con_tc_virtual = course.info_descuento_promocion.precio_final_con_tc
                           course.info_descuento_promocion.precio_final_con_tc_sin_descuento_virtual = course.info_descuento_promocion.precio_final_con_tc_sin_descuento

                        }
                      })
                  }
                  else{
                      this.$swal({
                          title: 'Error!',
                          text: response.data.message,
                          icon: 'error',
                          confirmButtonColor: '#0F80C1',
                          confirmButtonText: 'Aceptar',
                      })

                      this.aplicaCuponDescuento = false
                      this.infoCupon = null
                      this.carritoConCupon = this.carrito
                  }
                  this.closeLoadingInDiv()
              }
          )
      },
      calcularPendientes(total){
        this.valor_a_pagar = (total * this.porcentaje_facilidad_pago).toFixed(2)
        this.valor_pendiente = (total * (1 - this.porcentaje_facilidad_pago)).toFixed(2)
      }
   },
    computed: {
        // tarjetaPercentage() {
        //     return 100 - this.transferenciaPercentage;
        // },
        subtotalCarrito() {
            let carrito =  JSON.parse(localStorage.getItem("carritoLogiga"))
            // let carrito = JSON.parse(this.cookies.get("carritoLogiga"))
            if(this.aplicaCuponDescuento && this.cuponDescuento !== ''){
                carrito = this.carritoConCupon
            }
            let subtotal = 0.00
            carrito.forEach((course) => {
               let precio = 0.00

                if(course.modalidad_seleccionada ==='SEMIPRESENCIAL' && course.tiene_modalidad_hibrida){
                //   console.log("entra primer if")
                   if(course.info_descuento_promocion && course.info_descuento_promocion['aplica_descuento']){
                      precio += parseFloat(course.info_descuento_promocion['precio_postdescuento_hibrida'])
                   }
                   else{
                      precio += parseFloat(course.precio)
                   }
                }

                else if(course.modalidad_seleccionada ==='ONLINE' && course.tiene_modalidad_virtual){
                //   console.log("entra segundo if")
                //   console.log("precio post virtual es--------->")
                //   console.log(parseFloat(course.info_descuento_promocion['precio_postdescuento_virtual']))
                  if(course.info_descuento_promocion && course.info_descuento_promocion['aplica_descuento']) {
                    precio += parseFloat(course.info_descuento_promocion['precio_postdescuento_virtual'])
                  }
                  else{
                      precio += parseFloat(course.precio)
                   }
                }

                else{
                //   console.log("else")
                   if(course.info_descuento_promocion && course.info_descuento_promocion['aplica_descuento']){
                      precio += parseFloat(course.info_descuento_promocion['precio_postdescuento'])
                   }
                   else{
                      precio += parseFloat(course.precio)
                   }
                }

               if(this.metodoPago === 'TAR'){
                 precio += precio * this.porcentaje_cobro_tc
               } else if (this.metodoPago === 'PAGM') {
                 precio += (precio * this.porcentaje_cobro_tc * (100 - this.transferenciaPercentage)/100)
               } else if (this.metodoPago === 'FACP') {
                 if (this.metodoFacilidadPago === 'TAR'){
                    precio += (precio * this.porcentaje_facilidad_pago * this.porcentaje_cobro_tc)
                 }
               }
                // console.log("precio final de producto es------>")
                // console.log(precio)
               subtotal += precio
            })

            return subtotal.toFixed(2)
        },
        calcularImpuestos() {
            let subtotal = this.subtotalCarrito
            let impuestos = subtotal * this.porcentajeImpuestos/100

            return impuestos.toFixed(2)
        },
        calcularTotal() {
            let subtotal = String(this.subtotalCarrito)
            let impuestos = String(this.calcularImpuestos)

            let total = parseFloat(subtotal) + parseFloat(impuestos)
            this.calcularPendientes(total)
            return total.toFixed(2)
        },
        carrito() {
            return this.$store.getters.getCarrito
        },
        getValidationSchema(){
            // if(this.metodoPago==='BAN'){
            //     return this.checkoutSchemaV1
            // }
            // else if(this.metodoPago==='TAR'){
            //     return this.checkoutSchemaV2
            // }

            return this.checkoutSchemaV1
        }
    },
    mounted() {
        this.obtenerConfigPaymentez()
        this.inicializarPaymentezPaycard()
    },
};
</script>
<style>
.custom-file-upload {
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    background-color: #007bff;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s;
}
.custom-file-upload:hover {
    background-color: #0056b3;
}
.custom-file-upload i {
    margin-right: 8px;
}
#upload_file {
    display: none;
}
</style>
