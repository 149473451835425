/* eslint-disable */

<template>
<div class="col-xxl-4 col-xl-4 col-lg-4">
   <div class="course__sidebar pl-70 p-relative">
      <div class="course__shape">
         <img class="course-dot" src="../../assets/img/course/course-dot.png" alt="">
      </div>
      <div class="course__sidebar-widget-2 white-bg mb-20">
         <div class="course__video">
            <div class="course__video-thumb w-img mb-25" v-if="course.video_previa">
               <img v-if="course.course_image" :src="course.course_image">
               <img v-else src="../../assets/img/course/video/course-video.jpg">
               <div class="course__video-play">
                  <button v-b-modal.modal-center :href="course.video_previa" data-fancybox="" class="play-btn"> <i class="fas fa-play"></i> </button>
               </div>
            </div>
            <div class="mb-25">
               <div class="col-md-12">
                  <div v-if="course.tiene_modalidad_hibrida && modalidadSeleccionada=='SEMIPRESENCIAL'">
                     <div class="course__video-price" v-if="course.info_descuento_promocion && course.info_descuento_promocion.aplica_descuento">
                       <h5>$ {{ course.info_descuento_promocion.precio_postdescuento_hibrida }}</h5>
      <!--                  <h5>$74.<span>00</span> </h5>-->
                        <h5 class="old-price">$ {{ course.precio_modalidad_hibrida }}</h5>
                     </div>
                     <div class="course__video-price" v-else-if="course.info_descuento_promocion && !course.info_descuento_promocion.aplica_descuento">
                        <h5>$ {{ course.precio_modalidad_hibrida }}</h5>
                     </div>
                     <div style="width: 70%;" class="course__video-discount" v-if="course.info_descuento_promocion && course.info_descuento_promocion.aplica_descuento">
                        <span>{{ parseFloat(course.info_descuento_promocion.porcentaje_descuento).toFixed(0) }}% Pronto Pago</span>
                     </div>
                  </div>

                  <div v-else-if="course.tiene_modalidad_virtual && modalidadSeleccionada=='ONLINE'">
                     <div class="course__video-price" v-if="course.info_descuento_promocion && course.info_descuento_promocion.aplica_descuento">
                       <h5>$ {{ course.info_descuento_promocion.precio_postdescuento_virtual }}</h5>
      <!--                  <h5>$74.<span>00</span> </h5>-->
                        <h5 class="old-price">$ {{ course.precio_modalidad_virtual }}</h5>
                     </div>
                     <div class="course__video-price" v-else-if="course.info_descuento_promocion && !course.info_descuento_promocion.aplica_descuento">
                        <h5>$ {{ course.precio_modalidad_virtual }}</h5>
                     </div>
                     <div style="width: 70%;" class="course__video-discount" v-if="course.info_descuento_promocion && course.info_descuento_promocion.aplica_descuento">
                        <span>{{ parseFloat(course.info_descuento_promocion.porcentaje_descuento).toFixed(0) }}% Pronto Pago</span>
                     </div>
                  </div>

                  <div v-else>
                     <div class="course__video-price" v-if="course.info_descuento_promocion && course.info_descuento_promocion.aplica_descuento">
                       <h5>$ {{ course.info_descuento_promocion.precio_postdescuento }}</h5>
      <!--                  <h5>$74.<span>00</span> </h5>-->
                        <h5 class="old-price">$ {{ course.precio }}</h5>
                     </div>
                     <div class="course__video-price" v-else-if="course.info_descuento_promocion && !course.info_descuento_promocion.aplica_descuento">
                        <h5>$ {{ course.precio }}</h5>
                     </div>
                     <div style="width: 70%;" class="course__video-discount" v-if="course.info_descuento_promocion && course.info_descuento_promocion.aplica_descuento">
                        <span>{{ parseFloat(course.info_descuento_promocion.porcentaje_descuento).toFixed(0) }}% Pronto Pago</span>
                     </div>
                  </div>
               </div>
            </div>
            <div class="course__video-meta mb-25 d-flex align-items-center justify-content-between" v-if="course.tiene_modalidad_hibrida && course.tiene_modalidad_virtual">
               <div class="col-md-12">
                  <h3 style="font-size:20px;">Modalidad:</h3>
                    <b-form-select v-model="modalidadSeleccionada" @change="notifyChange">
                       <b-form-select-option value="SEMIPRESENCIAL">Semi presencial</b-form-select-option>
                       <b-form-select-option value="ONLINE">Online</b-form-select-option>
                   </b-form-select>
               </div>
            </div>
            <div class="course__video-content mb-35">
               <ul>
<!--                  <li class="d-flex align-items-center">-->
<!--                     <div class="course__video-icon">-->
<!--                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">-->
<!--                           <path class="st0" d="M2,6l6-4.7L14,6v7.3c0,0.7-0.6,1.3-1.3,1.3H3.3c-0.7,0-1.3-0.6-1.3-1.3V6z"/>-->
<!--                           <polyline class="st0" points="6,14.7 6,8 10,8 10,14.7 "/>-->
<!--                        </svg>-->
<!--                     </div>-->
<!--                     <div class="course__video-info">-->
<!--                        <h5 v-for="teacher in course.teachers" :key="teacher.token"><span>Docente :</span> {{ teacher.display_name }}</h5>-->
<!--                     </div>-->
<!--                  </li>-->
                  <li class="d-flex align-items-center">
                     <div class="course__video-icon">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve">

                           <path class="st0" d="M4,19.5C4,18.1,5.1,17,6.5,17H20"/>
                           <path class="st0" d="M6.5,2H20v20H6.5C5.1,22,4,20.9,4,19.5v-15C4,3.1,5.1,2,6.5,2z"/>
                        </svg>
                     </div>
                     <div class="course__video-info" v-if="course.tipo_registro=='CURSO'">
                        <h5><span>Secciones :</span>{{ course.modulos.length }}</h5>
                     </div>
                     <div class="course__video-info" v-else-if="course.tipo_registro=='PROGRAMA'">
                        <h5><span>Cursos :</span>{{ course.detalles.length }}</h5>
                     </div>
                  </li>
<!--                  <li class="d-flex align-items-center">-->
<!--                     <div class="course__video-icon">-->
<!--                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">-->
<!--                           <circle class="st0" cx="8" cy="8" r="6.7"/>-->
<!--                           <polyline class="st0" points="8,4 8,8 10.7,9.3 "/>-->
<!--                        </svg>-->
<!--                     </div>-->
<!--                     <div class="course__video-info">-->
<!--                        <h5><span>Duration :</span>6 weeks</h5>-->
<!--                     </div>-->
<!--                  </li>-->
<!--                  <li class="d-flex align-items-center">-->
<!--                     <div class="course__video-icon">-->
<!--                        <svg>-->
<!--                           <path class="st0" d="M13.3,14v-1.3c0-1.5-1.2-2.7-2.7-2.7H5.3c-1.5,0-2.7,1.2-2.7,2.7V14"/>-->
<!--                           <circle class="st0" cx="8" cy="4.7" r="2.7"/>-->
<!--                        </svg>-->
<!--                     </div>-->
<!--                     <div class="course__video-info">-->
<!--                        <h5><span>Inscritos :</span>{{ course.total_students }} estudiantes</h5>-->
<!--                     </div>-->
<!--                  </li>-->
                  <li class="d-flex align-items-center">
                     <div class="course__video-icon">
                        <svg>
                           <circle class="st0" cx="8" cy="8" r="6.7"/>
                           <line class="st0" x1="1.3" y1="8" x2="14.7" y2="8"/>
                           <path class="st0" d="M8,1.3c1.7,1.8,2.6,4.2,2.7,6.7c-0.1,2.5-1,4.8-2.7,6.7C6.3,12.8,5.4,10.5,5.3,8C5.4,5.5,6.3,3.2,8,1.3z"/>
                        </svg>
                     </div>
                     <div class="course__video-info">
                        <h5><span>Idioma :</span>Español</h5>
                     </div>
                  </li>
               </ul>
            </div>
            <div class="course__payment mb-35">
               <h3>Métodos de pago:</h3>
<!--               <a href="#">-->
<!--                  <img src="../../assets/img/course/payment/payment-1.png" alt="">-->
<!--               </a>-->
               <img src="../../assets/img/course/payment/mastercard-card.png" alt="mastercard card" height="36">
               <img src="../../assets/img/course/payment/visa-card.jpg" alt="visa card" height="36" width="50">
               <img src="../../assets/img/course/payment/american-card.png" alt="american express card" height="36">
               <img src="../../assets/img/course/payment/diners-card.jpg" alt="diners club card" height="36" width="100">
               <img src="../../assets/img/course/payment/banco-pichincha.png" alt="banco pichincha" height="30" width="120" class="mt-3">
               <img src="../../assets/img/course/payment/cash-payment.png" alt="cash" height="45" width="70" class="mt-3">
            </div>
            <div class="course__enroll-btn mb-35">
              <a v-if="!agregadoACarrito && !agregadoACarritoEnVista && course.compra_activa" @click="agregarCursoEnCarrito(course)" href="javascript:void(0);" class="e-btn e-btn-7 w-100">
                  Agregar al carrito
                  <i class="far fa-arrow-right"></i>
              </a>
              <a v-else-if="course.compra_activa" href="javascript:void(0);" class="e-btn e-btn-border w-100" style="cursor: not-allowed;">
                  Ya agregado
              </a>
<!--               <router-link to="/login" class="e-btn e-btn-7 w-100">Agregar al carrito-->
<!--                  <i class="far fa-arrow-right"></i>-->
<!--               </router-link>-->
            </div>

            <div class="d-flex align-items-center">
               <div class="course__video-icon">
                  <svg class="mail" viewBox="0 0 24 24">
                     <path class="st0" d="M4,4h16c1.1,0,2,0.9,2,2v12c0,1.1-0.9,2-2,2H4c-1.1,0-2-0.9-2-2V6C2,4.9,2.9,4,4,4z"/>
                     <polyline class="st0" points="22,6 12,13 2,6 "/>
                  </svg>
               </div>
               <div class="course__video-info">
                  <h5>Para mayor información y consultar facilidades de pago, <a style="color:#0F80C1" href="https://cursos.logigacorp.com/contacto" target="_blank">contáctenos</a>.</h5>
               </div>
            </div>
         </div>
      </div>

      <!-- RelatedCourse start -->
<!--      <RelatedCourse DetailsPage="course__sidebar-widget-2 white-bg mb-20" />-->
      <!-- RelatedCourse end -->

   </div>
</div>

  <!-- video modal  start-->
<div>
   <div class="video-modal-wrapper">
      <b-modal id="modal-center" centered>
         <p><iframe :src="course.video_previa" title="YouTube video player" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></p>
      </b-modal>
    </div>
</div>
<!-- video modal  end-->
</template>

<script>
// import RelatedCourse from '../common/RelatedCourse.vue';

import { useCookies } from "vue3-cookies";
import rutasjson from '@/assets/utils/rutas.json'
import axios from "@/axios";

export default {
   name:'courseSidebar',
   components:{
      // RelatedCourse,
   },
    props: {
        course: Object
    },
    setup() {
      const { cookies } = useCookies();
      return { cookies };
    },
    data () {
        return {
          agregadoACarritoEnVista: false,
          rutaCompleta: rutasjson.rutaCompleta,
          agregadoACarrito: false,
           modalidadSeleccionada: 'SEMIPRESENCIAL'
        }
    },
    methods:{
       agregarCursoEnCarrito(course){
           let carrito =  JSON.parse(localStorage.getItem("carritoLogiga"))
         // let carrito =  JSON.parse(this.cookies.get("carritoLogiga"))

          let infoDescuento = {}
         infoDescuento = course.info_descuento_promocion
         if(this.modalidadSeleccionada == 'SEMIPRESENCIAL' && course.tiene_modalidad_hibrida){
           // infoDescuento = course.info_descuento_promocion
           infoDescuento.precio_postdescuento = infoDescuento.precio_postdescuento_hibrida
         }
         else if(this.modalidadSeleccionada == 'ONLINE' && course.tiene_modalidad_virtual){
           // infoDescuento = course.info_descuento_promocion
           infoDescuento.precio_postdescuento = infoDescuento.precio_postdescuento_virtual
         }

         let course_dict = {
            token: course.token,
            name: course.name,
            canvas_id: course.canvas_id,
            course_image: course.course_image,
            precio: course.precio,
            teachers: course.teachers,
            codigo: course.codigo,
            info_descuento_promocion: infoDescuento,
            tipo_registro: course.tipo_registro,
            modalidad_seleccionada: this.modalidadSeleccionada,
           tiene_modalidad_hibrida: course.tiene_modalidad_hibrida,
           tiene_modalidad_virtual: course.tiene_modalidad_virtual
         }

         carrito.push(course_dict)
         this.$store.commit('agregarCursoEnCarrito', course_dict)

         let subtotal = 0.00
          carrito.forEach((course) => {
             let precio = 0.00
             if(course.info_descuento_promocion && course.info_descuento_promocion['aplica_descuento']){
                precio += parseFloat(course.info_descuento_promocion['precio_postdescuento'])
             }
             else{
                precio += parseFloat(course.precio)
             }
              subtotal += precio
          })

          this.$store.commit('setSubtotal', subtotal.toFixed(2))
           localStorage.setItem("carritoSubtotal", subtotal.toFixed(2))
          // this.cookies.set("carritoSubtotal", subtotal.toFixed(2), '30d', '', rutasjson.domain)

         // console.log("carrito despues de agregar en carrito es-------->")
         // console.log(carrito)
         this.$store.commit('setCarrito', carrito)
         localStorage.setItem("carritoLogiga", JSON.stringify(carrito))
         // this.cookies.set("carritoLogiga", JSON.stringify(carrito), '30d', '', rutasjson.domain)
         // console.log("Carrito guardado en cookie es------->")
         // console.log(JSON.parse(this.cookies.get("carritoLogiga")))
          this.$swal({
              title: 'Agregado',
              text: 'Fue agregado al carrito exitosamente',
              icon: 'success',
              confirmButtonColor: '#0F80C1',
              confirmButtonText: 'Aceptar',
          })
          this.registrarAgregarACarrito(course)
          this.agregadoACarritoEnVista = true
       },
         registrarAgregarACarrito(course){
             axios.post('api/orden/agregar-curso-carrito/',{
                 curso: course.token,
                tipo_registro: course.tipo_registro
             })
         },
       verificarAgregadoACarrito(){
           let carrito =  JSON.parse(localStorage.getItem("carritoLogiga"))
            // let carrito =  JSON.parse(this.cookies.get("carritoLogiga"))
            let that = this
            let agregado = false
          // console.log("agregado a carrito-----------------")
            carrito.forEach(function(curso) {
                // console.log("---------------------")
                // console.log(curso['token'])
                // console.log("++++++++++++++++++++")
                // console.log(that.$route.params.codigo)
                if(curso['codigo'] === that.$route.params.codigo){
                    agregado = true
                }
            })
            this.agregadoACarrito = agregado
            this.agregadoACarritoEnVista = agregado
       },
       notifyChange(newValue) {
         this.$emit('modalidad-change', newValue);
      }
    },
    computed: {
    },
    watch: {
      modalidadSeleccionada(newValue) {
        if(this.course.tiene_modalidad_hibrida && newValue=='SEMIPRESENCIAL'){
           // eslint-disable-next-line
           this.course.precio = this.course.precio_modalidad_hibrida
        }
        else if(this.course.tiene_modalidad_virtual && newValue=='ONLINE'){
           // eslint-disable-next-line
           this.course.precio = this.course.precio_modalidad_virtual
        }
      }
    },

    mounted() {
         let that = this
         window.setInterval(function(){
               that.verificarAgregadoACarrito()
         }, 500)

      // if(this.course.tiene_modalidad_hibrida){
      //   this.modalidadSeleccionada = 'SEMIPRESENCIAL'
      // }
      // else if(this.course.tiene_modalidad_virtual){
      //   this.modalidadSeleccionada = 'ONLINE'
      // }
    },
};
</script>

