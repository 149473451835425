<template>
   <section class="about__area pb-50">
      <div class="container">
         <div class="row">
            <div class="col-xxl-5 offset-xxl-1 col-xl-6 col-lg-6 pb-70 pt-70">
               <div class="about__thumb-wrapper">

<!--                  <div class="about__review">-->
<!--                     <h5> <span>8,200+</span> five ster reviews</h5>-->
<!--                  </div>-->
                  <div class="about__thumb text-center" style="font-size:34px;">
                     <span class="fecha_semillero page__title-pre hero__quote-animation" style="max-width: 530px;font-size: 34px!important;padding-top: 20px; background-color: #0F80C1; height:64px;">Inicio: 31 de Marzo del 2025 </span>
                     <img class="imagen_semillero" src="../../assets/img/about/portada_semillero_v3.jpg" alt="" style="max-width: 530px;">
                  </div>
                  <div class="about__banner mt--210">
<!--                     <img src="../../assets/img/about/about-banner.jpg" alt="">-->
<!--                     <img src="../../assets/img/logiga/portada_semillero.jpg" alt="" height="240" width="310">-->
                  </div>
<!--                  <div class="about__student ml-270 mt&#45;&#45;80">-->
<!--                     <a href="#">-->
<!--                        <img src="../../assets/img/about/student-4.jpg" alt="">-->
<!--                        <img src="../../assets/img/about/student-3.jpg" alt="">-->
<!--                        <img src="../../assets/img/about/student-2.jpg" alt="">-->
<!--                        <img src="../../assets/img/about/student-1.jpg" alt="">-->
<!--                     </a>-->
<!--                     <p>Join over <span>4,000+</span> students</p>-->
<!--                  </div>-->
               </div>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-6 pt-70">
               <div class="about__content pl-70 pr-60">
                  <div class="section__title-wrapper mb-25">
                     <h2 class="section__title"><span class="yellow-bg-big">Semillero: <img src="../../assets/img/shape/yellow-bg-2.png" alt=""></span>Ejecutivo<br> de cuenta aduanero </h2>
                     <p style="text-align: justify;">
                        El Proyecto "Semillero" ha sido meticulosamente diseñado para garantizar una experiencia de aprendizaje efectiva y práctica.
                        Este plan de estudios sólido y relevante abarca todos los aspectos claves que un ejecutivo de comercio exterior debe tener. Brindamos:
                     </p>
                  </div>
                  <div class="about__list mb-35">
                     <ul>
                        <li class="d-flex align-items-center"> <span>&#10003;</span> Certificado de Asistencia por cada Módulo</li>
                        <li class="d-flex align-items-center"> <span>&#10003;</span> Examen de Habilidades Blandas al finalizar el programa</li>
                        <li class="d-flex align-items-center"> <span>&#10003;</span> Certificado de Cumplimiento Exitoso del Programa</li>
                        <li class="d-flex align-items-center"> <span>&#10003;</span> Informe Gerencial para bolsa de trabajo ADA</li>
                     </ul>
                  </div>
<!--                  <router-link to="/contact" class="e-btn e-btn-border">Ver programa</router-link>-->
                  <a class="e-btn e-btn-border" href="https://cursos.logigacorp.com/programa/semillero" target="_blank">Ver programa</a>
               </div>
            </div>
         </div>
      </div>
   </section>
</template>

<script>
export default {
   name:'ProgramaSemilleroArea'
};
</script>

<style>
   @media (min-width: 576px){
      .imagen_semillero{
         width:530px!important;
      }
      .fecha_semillero{
         width:530px!important;
         height:64px!important;
      }
   }

   @media (max-width: 576px){
      .fecha_semillero{
         height:114px!important;
      }
   }
</style>
