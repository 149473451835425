<template>
 <section class="cart-area pt-100 pb-100">
   <div class="container">
      <div class="row">
         <div class="col-12" v-if="carrito.length > 0">
            <div class="table-content table-responsive">
               <table class="table">
                     <thead>
                        <tr>
                           <th class="product-thumbnail">Curso</th>
                           <th class="cart-product-name">Docente</th>
                           <th class="product-subtotal">Precio</th>
                           <th class="product-remove"></th>
                        </tr>
                     </thead>
                     <tbody class="border-0">
                        <tr v-for="(course, index) in carrito" :key="course.token">
                           <td class="product-thumbnail">
                             <div class="d-flex align-items-center">
                              <router-link :to="`/curso/${course.codigo}`" class="mr-25">
                                  <img v-if="course.course_image" :src="course.course_image" alt="course-image" style="border-radius: 10%; height: 75px;">
                                  <img v-else src="@/assets/img/pages/not-available.png" alt="course-image" style="height: 75px;">
                                  <v-tooltip
                                    activator="parent"
                                    location="top"
                                  >
                                      Ver curso
                                  </v-tooltip>
                              </router-link>
                              <router-link :to="`/curso/${course.codigo}`" class="align-items-center" v-if="course.tipo_registro=='CURSO'">
                                  {{ course.name }} {{ course.modalidad_seleccionada }}
                                  <v-tooltip
                                    activator="parent"
                                    location="right"
                                  >
                                      Ver curso
                                  </v-tooltip>
                              </router-link>
                              <router-link :to="`/programa/${course.codigo}`" class="align-items-center" v-if="course.tipo_registro=='PROGRAMA'">
                                  {{ course.name }} {{ course.modalidad_seleccionada }}
                                  <v-tooltip
                                    activator="parent"
                                    location="right"
                                  >
                                      Ver programa
                                  </v-tooltip>
                              </router-link>
                             </div>
                           </td>
                           <td class="product-name">
                              <div class="course__teacher" v-for="teacher in course.teachers" :key="teacher.token">
                                 <div class="course__teacher-thumb mr-15">
                                    <a v-if="teacher.enlace_linkedin" :href="teacher.enlace_linkedin" target="_blank">
                                      <img :src="teacher.avatar_image_url" alt="">
                                    </a>

                                    <img :src="teacher.avatar_image_url" alt="" v-else>
                                 </div>
                                 <span>
                                    <b>Docente: </b>
                                    <a v-if="teacher.enlace_linkedin" :href="teacher.enlace_linkedin" target="_blank"> {{ teacher.display_name }}</a>
                                    <a v-else href="#"> {{ teacher.display_name }}</a>
                                 </span>
                              </div>
                           </td>
                           <td class="product-price course__status" v-if="course.info_descuento_promocion && course.info_descuento_promocion.aplica_descuento">
                               <span class="amount green">$ {{ course.info_descuento_promocion.precio_postdescuento }}</span>
                               <br>
                               <span class="old-price">$ {{ course.precio}}</span>
                           </td>
                           <td class="product-price" v-else-if="course.info_descuento_promocion && !course.info_descuento_promocion.aplica_descuento">
                               <span class="amount">$ {{ course.precio }}</span>
                           </td>
                           <td class="product-remove">
                                <a href="javascript:void(0);" @click="preguntarEliminarCursoEnCarrito(index)">
                                    <v-tooltip
                                      activator="parent"
                                      location="right"
                                    >
                                        Quitar
                                    </v-tooltip>
                                    <i class="fa fa-times"></i>
                                </a>
                           </td>
                        </tr>
                     </tbody>
               </table>
            </div>
<!--            <div class="row">-->
<!--               <div class="col-12">-->
<!--                     <div class="coupon-all">-->
<!--                        <div class="coupon d-sm-flex align-items-center">-->
<!--                           <input id="coupon_code" class="input-text" name="coupon_code" value="" placeholder="Coupon code" type="text">-->
<!--                           <button class="e-btn" name="apply_coupon"-->
<!--                           type="submit">Apply coupon</button>-->
<!--                        </div>-->
<!--                        <div class="coupon2">-->
<!--                           <button class="e-btn" name="update_cart"-->
<!--                           type="submit">Update cart</button>-->
<!--                        </div>-->
<!--                     </div>-->
<!--               </div>-->
<!--            </div>-->
            <div class="row justify-content-end">
               <div class="col-md-5 ml-auto">
                     <div class="cart-page-total">
                        <h2>Valores totales</h2>
                        <ul class="mb-20">
                           <li>Subtotal <span>${{ valorSubtotal }}</span></li>
                            <li>Impuestos (IVA 15%) <span>${{ valorImpuestos }}</span></li>
                           <li><b>Total</b> <span><b>${{ valorTotal }}</b></span></li>
                        </ul>
                        <router-link class="e-btn e-btn-border" to="/checkout">Proceder al pago</router-link>
                     </div>
               </div>
            </div>
         </div>
        <div v-else class="text-center">
            No hay registros para mostrar
        </div>
      </div>
   </div>
 </section>
</template>

<script>
import { useCookies } from "vue3-cookies";
// import rutasjson from '@/assets/utils/rutas.json'

export default {
   name:'CartArea',
    data () {
      return {
        porcentajeImpuestos: 15,
        valorSubtotal: 0.00,
        valorImpuestos: 0.00,
        valorTotal: 0.00
      }
    },
    setup() {
      const { cookies } = useCookies();
      return { cookies };
    },
    methods: {
        preguntarEliminarCursoEnCarrito(indexCourse){
            this.$swal({
              title: 'Quitar curso de carrito',
              text: '¿Está seguro que desea quitar el curso?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#0F80C1',
              confirmButtonText: 'Aceptar',
              cancelButtonText: 'Cancelar',
            }).then(result => {
              if (result.value) {
                this.eliminarCursoEnCarrito(indexCourse)
              }
            })
        },
        eliminarCursoEnCarrito(indexCourse){
            this.$store.commit('eliminarCursoEnCarrito', indexCourse)
            let carrito = this.$store.getters.getCarrito

            let subtotal = 0.00
            carrito.forEach((course) => {
               let precio = 0.00
               if(course.info_descuento_promocion && course.info_descuento_promocion['aplica_descuento']){
                  precio += parseFloat(course.info_descuento_promocion['precio_postdescuento'])
               }
               else{
                  precio += parseFloat(course.precio)
               }
                subtotal += precio
            })

            this.$store.commit('setSubtotal', subtotal.toFixed(2))

            localStorage.setItem("carritoSubtotal", subtotal.toFixed(2))
            // this.cookies.set("carritoSubtotal", subtotal.toFixed(2), '30d', '', rutasjson.domain)

            localStorage.setItem("carritoLogiga", JSON.stringify(carrito))
            // this.cookies.set("carritoLogiga", JSON.stringify(carrito), '30d', '', rutasjson.domain)

            this.subtotalCarrito()
            this.calcularImpuestos()
            this.calcularTotal()
            this.$swal({
                title: 'Quitado',
                text: 'Fue quitado del carrito exitosamente',
                icon: 'success',
                confirmButtonColor: '#0F80C1',
                confirmButtonText: 'Aceptar',
            })
        },
        subtotalCarrito() {
            this.valorSubtotal = 0.00
            let carrito =  JSON.parse(localStorage.getItem("carritoLogiga"))
            // let carrito = JSON.parse(this.cookies.get("carritoLogiga"))
            carrito.forEach((course) => {
               let precio = 0.00
               if(course.info_descuento_promocion && course.info_descuento_promocion['aplica_descuento']){
                  precio += parseFloat(course.info_descuento_promocion['precio_postdescuento'])
               }
               else{
                  precio += parseFloat(course.precio)
               }
                this.valorSubtotal += precio
            })

            this.valorSubtotal = this.valorSubtotal.toFixed(2)
        },
        calcularImpuestos() {
            this.valorImpuestos = this.valorSubtotal * this.porcentajeImpuestos/100

            this.valorImpuestos = this.valorImpuestos.toFixed(2)
        },
        calcularTotal() {
            let subtotal = String(this.valorSubtotal)
            let impuestos = String(this.valorImpuestos)

            let total = parseFloat(subtotal) + parseFloat(impuestos)
            this.valorTotal = total.toFixed(2)
        }
    },
    computed: {
        carrito() {
            return this.$store.getters.getCarrito
        }
    },
    mounted() {
        this.subtotalCarrito()
        this.calcularImpuestos()
        this.calcularTotal()
    }
};
</script>

